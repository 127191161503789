import { ScrollViewGrow } from "@core/atoms"
import { useAuthContext } from "@core/context/auth"
import { FmkComboBox } from "@core/formik/FmkComboBox"
import { usePrevious } from "@core/hooks"
import { useValidationSchema } from "@core/hooks/useValidationSchema"
import { ContentContainerColumn, ContentContainerRow } from "@core/misc-components/ContentContainer"
import { AppBar } from "@core/molecules/AppBar"
import { user_info_query } from "@core/queries"
import { scolarity_main_query } from "@core/queries/scolarity_main"
import { studies_level_query } from "@core/queries/studies_level"
import { openapi } from "@core/services/openapi"
import { rasmik, Teacher } from "@core/services/rasmik"
import { FmkUtils } from "@core/utils/formik"
import { FontAwesome5 } from "@expo/vector-icons"
import { SubmitButton, TextAreaControl } from "@native-base/formik-ui"
import type { NavigationProp } from "@react-navigation/native"
import type { StackScreenProps } from "@react-navigation/stack"
import { Formik, useFormikContext } from "formik"
import { deburr, sortBy } from "lodash"
import { Box, Button, Center, Heading, HStack, Icon, Stack, Text } from "native-base"
import React, { useEffect } from "react"
import { useErrorBoundary } from "react-error-boundary"

import type { MainStackParamsList } from "../MainStack"

export class FormValues {
    @FmkUtils.DefField scolarityMain: number | null
    @FmkUtils.DefField scolarityComplement: number | null
    @FmkUtils.DefField scolarityLevel: number | null
    @FmkUtils.DefField freeComment: string
}

const FIELDS = FmkUtils.getFields(FormValues)

export function EditScolarityFormScreen({ navigation, route }: StackScreenProps<MainStackParamsList, "EditScolarityForm">) {
    return (
        <>
            <AppBar onBack={() => navigation.navigate("MainTabs", { screen: "User" })}>
                <ContentContainerRow flex={1}>
                    <Heading size="md">Mes études</Heading>
                </ContentContainerRow>
            </AppBar>
            <ScrollViewGrow>
                <ContentContainerColumn>
                    <EditScolarityForm navigation={navigation} isSplashScreen={false} onSnooze={() => {}} />
                </ContentContainerColumn>
            </ScrollViewGrow>
        </>
    )
}

type EditScolarityFormProps = {
    navigation: NavigationProp<MainStackParamsList>
    isSplashScreen: boolean
    onSnooze(): void
}

export function EditScolarityForm(props: EditScolarityFormProps) {
    const schema = useValidationSchema(
        (Yup) =>
            Yup.object().shape({
                [FIELDS.scolarityMain]: Yup.string().nullable().required("Requis"),
                [FIELDS.scolarityComplement]: Yup.string().nullable(),
                [FIELDS.scolarityLevel]: Yup.string().nullable().required("Requis"),
                [FIELDS.freeComment]: Yup.string().notRequired(),
            }),
        []
    )

    const auth = useAuthContext()
    const teacherId = auth.userId

    const { userInfoQuery } = user_info_query.use({ teacherId: auth.userId })
    const { scolarityMainQuery } = scolarity_main_query.use({})
    const { studiesLevelQuery } = studies_level_query.use({})
    const { showBoundary } = useErrorBoundary()

    return (
        <Formik<FormValues>
            initialValues={{
                [FIELDS.scolarityMain]: userInfoQuery.data?.ScolarityMain?.id || null,
                [FIELDS.scolarityComplement]: userInfoQuery.data?.ScolarityComplement?.id || null,
                [FIELDS.scolarityLevel]: userInfoQuery.data?.StudyLevel?.id || null,
                [FIELDS.freeComment]: "",
            }}
            enableReinitialize
            validationSchema={schema}
            onSubmit={async (values, { setSubmitting }) => {
                // console.log("test ", values)

                try {
                    await auth.ensureValidToken()

                    const hasChanged =
                        values.scolarityMain !== (userInfoQuery.data?.ScolarityMain?.id ?? null) ||
                        values.scolarityComplement !== (userInfoQuery.data?.ScolarityComplement?.id ?? null) ||
                        values.scolarityLevel !== (userInfoQuery.data?.StudyLevel?.id ?? null)

                    const newTeacher = await rasmik
                        .pushOne(Teacher)
                        .pushDef({
                            allow: "update",
                            children: {
                                ScolarityMain: { allow: "pk" },
                                ScolarityComplement: { allow: "pk" },
                                StudyLevel: { allow: "pk" },
                            },
                        })
                        .data({ id: teacherId, ScolarityMain: values.scolarityMain, StudyLevel: values.scolarityLevel, ScolarityComplement: values.scolarityComplement, scolarityLastUpdatedAt: new Date() })
                        .readOptions({ children: { ScolarityMain: true, ScolarityComplement: true, StudyLevel: true } })
                        .run()

                    if (values.freeComment || hasChanged) {
                        const blocks = [
                            {
                                type: "divider",
                            },
                            {
                                type: "section",
                                text: {
                                    type: "mrkdwn",
                                    text: `<!channel> *Changement de scolarité*\n:memo:${userInfoQuery.data?.firstName} ${userInfoQuery.data?.lastName.toUpperCase()}`,
                                },
                            },
                            hasChanged && {
                                type: "section",
                                text: {
                                    type: "mrkdwn",
                                    text: `~${userInfoQuery.data?.ScolarityMain?.label ?? ""} ${userInfoQuery.data?.ScolarityComplement?.label ?? ""} (${userInfoQuery.data?.StudyLevel?.label ?? ""})~ -> ${
                                        newTeacher?.ScolarityMain?.label ?? ""
                                    } ${newTeacher?.ScolarityComplement?.label ?? ""} (${newTeacher?.StudyLevel?.label ?? ""})`,
                                },
                            },
                            values.freeComment && {
                                type: "section",
                                text: {
                                    type: "plain_text",
                                    text: `:warning:Commentaire à corriger :\n${values.freeComment}`,
                                },
                            },
                            values.freeComment && {
                                type: "actions",
                                elements: [
                                    {
                                        type: "button",
                                        style: "primary",
                                        url: `${process.env.DOPPLE_APP_URL}/profs/${userInfoQuery.data?.id}/profil`,
                                        text: {
                                            type: "plain_text",
                                            text: "Corriger sur Dopple",
                                            emoji: true,
                                        },
                                    },
                                ],
                            },
                        ].filter(Boolean)
                        openapi.teachersApp.notifySlack({ blocks, channel: "tuteurs" }).catch()
                    }

                    await userInfoQuery.refetch()
                    if (!props.isSplashScreen) {
                        props.navigation.navigate("MainTabs", { screen: "User" })
                    }
                } catch (e) {
                    showBoundary(e)
                } finally {
                    setSubmitting(false)
                }
            }}
        >
            {({ values }) => (
                <Stack space={2} my="5">
                    {/* <Heading size="md">Ma scolarité</Heading> */}

                    <FmkComboBox label="Je suis" configure={(opt) => ({ label: opt.label, value: opt.id })} name={FIELDS.scolarityMain} options={sortBy(scolarityMainQuery.data, (item) => deburr(item.label?.toLowerCase()))} />
                    <ScolarityComplement />

                    <FmkComboBox
                        label="Niveau"
                        configure={(opt) => ({ label: opt.label, value: opt.id })}
                        name={FIELDS.scolarityLevel}
                        options={studiesLevelQuery.data ?? []}
                        helperText="Année en cours ou meilleure année validée"
                    />

                    <Box my="5">
                        <TextAreaControl label="Si vos études ne figurent pas ci-dessus veuillez nous le notifier ici" name={FIELDS.freeComment} />
                        <Text fontSize="xs">Nous effectuerons les changements nécessaires dans les prochains jours</Text>
                    </Box>
                    {props.isSplashScreen ? (
                        <HStack padding={2} justifyContent="right" h="60px" space={2}>
                            <Button variant="solid" colorScheme="gray" onPress={() => props.onSnooze()} leftIcon={<Icon as={FontAwesome5} name="clock" size="xs" />}>
                                Plus tard
                            </Button>
                            <SubmitButton
                                leftIcon={values.freeComment.length > 0 ? <Icon as={FontAwesome5} name="paper-plane" color="white" size="xs" /> : <Icon as={FontAwesome5} name="save" size="xs" />}
                                colorScheme="pedagome"
                            >
                                {values.freeComment.length > 0 ? "Envoyer" : "Enregistrer"}
                            </SubmitButton>
                        </HStack>
                    ) : (
                        <Center>
                            <SubmitButton
                                leftIcon={values.freeComment.length > 0 ? <Icon as={FontAwesome5} name="paper-plane" color="white" size="xs" /> : <Icon as={FontAwesome5} name="save" size="xs" />}
                                colorScheme="pedagome"
                            >
                                {values.freeComment.length > 0 ? "Envoyer" : "Enregistrer"}
                            </SubmitButton>
                        </Center>
                    )}
                </Stack>
            )}
        </Formik>
    )
}

function ScolarityComplement() {
    const { scolarityMainQuery } = scolarity_main_query.use({})

    const ctx = useFormikContext<FormValues>()
    const scolarityComplements =
        scolarityMainQuery.data?.find((value) => {
            return value.id === ctx.values.scolarityMain
        })?.Complements || []

    const previousMain = usePrevious(ctx.values.scolarityMain)
    useEffect(() => {
        if (previousMain && ctx.values.scolarityMain && ctx.values.scolarityMain !== previousMain) {
            ctx.setFieldValue(FIELDS.scolarityComplement, null, false)
        }
    })

    return <FmkComboBox label="Complément" configure={(opt) => ({ label: opt.label, value: opt.id })} name={FIELDS.scolarityComplement} options={sortBy(scolarityComplements, (item) => deburr(item.label?.toLowerCase()))} />
}
