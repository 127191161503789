import { ScrollViewGrow } from "@core/atoms"
import { Card } from "@core/atoms/Card"
import { useAuthContext } from "@core/context/auth"
import { useCatchToBoundary, useCollapse, useDialog, useSpinner } from "@core/hooks"
import { ContentContainer, ContentContainerRow } from "@core/misc-components/ContentContainer"
import { AppBar } from "@core/molecules/AppBar"
import { current_proposition_lessons_query, match_infos_list_query, propositions_list_query } from "@core/queries"
import { openapi } from "@core/services/openapi"
import { DayDate, openAddressInMaps, toFrenchRelativeCalendar } from "@core/utils"
import { age, formatDurationMin, formatEuro, frequencyToText } from "@core/utils/format"
import { conditional, plural } from "@core/utils/textUtils"
import { FontAwesome5 } from "@expo/vector-icons"
import type { StackScreenProps } from "@react-navigation/stack"
import { isNil, sortBy } from "lodash"
import { DateTime } from "luxon"
import { Alert, Box, Button, Center, ChevronDownIcon, ChevronUpIcon, Divider, Heading, HStack, IconButton, Modal, Spacer, Stack, Text, useColorModeValue, useTheme, VStack } from "native-base"
import React, { type ReactNode } from "react"
import Collapsible from "react-native-collapsible"
import { RefreshControl } from "react-native-gesture-handler"

import type { MainStackParamsList } from "../MainStack"

declare module "react-native-collapsible" {
    export interface CollapsibleProps {
        children: ReactNode
    }
}

function useMatchesWithoutFirstLesson({ teacherId }: { teacherId: number | undefined }) {
    const { matchInfosListQuery } = match_infos_list_query.use({ teacherId })
    const { currentPropositionLessonsQuery } = current_proposition_lessons_query.use({ teacherId }, { staleTime: 5000 })

    //Matchs draft et attribués
    const draftAcceptedMatches = matchInfosListQuery.data?.allMatches.filter((m) => m.status === "DRAFT" && !!m.Teacher)

    const matchesWithoutFirstLesson = draftAcceptedMatches?.filter((match) => {
        // on regarde s'il y a une proposition plausible
        return !currentPropositionLessonsQuery.helpers.findByMatchId(match.id).some((lsn) => lsn.status === "DRAFT" || lsn.status === "BOOKED")
    })

    return { matchesWithoutFirstLesson }
}

export function useNotificationsCount() {
    const auth = useAuthContext()
    const { propositionsListQuery } = propositions_list_query.use({ teacherId: auth.userId })
    const { matchesWithoutFirstLesson } = useMatchesWithoutFirstLesson({ teacherId: auth.userId })
    return propositionsListQuery.helpers.pendingPropsCount() + propositionsListQuery.helpers.expiredShownPropsCount() + (matchesWithoutFirstLesson?.length ?? 0)
}

/**
 * Sur la page notifs, on affiche :
 * - Les props pas encore acceptées
 * - Les props acceptées mais sans 1er créneau.
 */

export function MessagesScreen({ navigation }: StackScreenProps<MainStackParamsList, "Messages">) {
    const auth = useAuthContext()

    const greyTextColor = useColorModeValue("light.600", "dark.600")
    const { propositionsListQuery } = propositions_list_query.use({ teacherId: auth.userId })
    const { matchesWithoutFirstLesson } = useMatchesWithoutFirstLesson({ teacherId: auth.userId })

    const scrollRefreshSpinner = useSpinner()
    const onRefresh = useCatchToBoundary(async () => {
        // console.log("SaisieScreen scroll refresh")
        alert("refresh")
        try {
            await auth.ensureValidToken()
            scrollRefreshSpinner.start()
            await propositionsListQuery.refetch()
        } finally {
            scrollRefreshSpinner.stop()
        }
    }, [auth, scrollRefreshSpinner, propositionsListQuery])

    const hasMessages = propositionsListQuery.helpers.pendingPropsCount() + propositionsListQuery.helpers.expiredShownPropsCount() + (matchesWithoutFirstLesson?.length ?? 0) > 0

    // if (isNil(auth) || isNil(auth.userId)/*  || propositionsListQuery.isLoading */) return <LoadingScreen />
    return (
        <VStack safeAreaBottom width="100%" h="100%" display="flex" alignItems="stretch" justifyContent="flex-start">
            <AppBar onBack={navigation.goBack}>
                <ContentContainerRow flex={1}>
                    <Heading size="md">Notifications</Heading>
                </ContentContainerRow>
            </AppBar>

            {!hasMessages && (
                <Center flex={1}>
                    <Text fontSize="xl">Rien à signaler 🍹</Text>
                </Center>
            )}
            {hasMessages && (
                <ScrollViewGrow refreshControl={<RefreshControl refreshing={scrollRefreshSpinner.loading} onRefresh={onRefresh} />}>
                    <ContentContainer space="15px" paddingY="15px" alignItems="center">
                        {propositionsListQuery.data?.pendingPropositions.map((prop) => {
                            return <MatchPropositionCard key={prop.id} propId={prop.id} />
                        })}
                        {matchesWithoutFirstLesson?.map((match) => {
                            return <MatchWithoutFirstLessonCard key={match.id} matchId={match.id} />
                        })}
                        {propositionsListQuery.data?.expiredPropositonsShown?.map((prop) => {
                            return <ExpiredPropositionCard key={prop.id} propId={prop.id} />
                        })}
                    </ContentContainer>
                </ScrollViewGrow>
            )}
        </VStack>
    )
}

function MatchPropositionCard({ propId }: { propId: number }) {
    const auth = useAuthContext()
    const { propositionsListQuery } = propositions_list_query.use({ teacherId: auth.userId })

    const acceptDialog = useDialog()
    const refuseDialog = useDialog()

    const tprop = propositionsListQuery.helpers.getPropById(propId)
    const firstLesson = sortBy(tprop?.Match.Lessons.filter((lsn) => lsn.status === "DRAFT"), (x) => x.startedAtPlanned).at(0) ?? null

    const latestExpiredLesson = sortBy(tprop?.Match.Lessons.filter((lsn) => lsn.status === "ABORTED"), (x) => x.startedAtPlanned).at(-1) ?? null

    const approxAddresses = (tprop?.Match?.Program?.Learner?.LessonAddresses ?? []).map((address) => `${address.streetTypeLabel} ${address.streetName} à ${address.cityName}`.replaceAll("  ", " "))

    const handleDialogSuccessAccept = useCatchToBoundary(async () => {
        if (!firstLesson?.id || !tprop?.Teacher) return
        await openapi.teachersApp.acceptMatchAndLessonProposition(firstLesson?.id, { teacherId: tprop?.Teacher, matchId: tprop.Match.id })
        await propositionsListQuery.refetch()
        acceptDialog.hide()
    })
    const handleDialogSuccessRefuse = useCatchToBoundary(async () => {
        if (!firstLesson?.id || !tprop?.Teacher) return
        await openapi.teachersApp.refuseMatchProposition(tprop.id)
        await propositionsListQuery.refetch()
        acceptDialog.hide()
    })

    const theme = useTheme()
    const iconColor = useColorModeValue(theme.colors.light[800], theme.colors.dark[800])

    const collapseLogic = useCollapse()
    if (!tprop) return null
    const bottomInfoText = `Proposé ${toFrenchRelativeCalendar(DateTime.fromJSDate(tprop.sentAt!))}`

    return (
        <Card py="10px">
            <Box alignItems="center" width="full" justifyContent="center" display="flex" flexDirection="row">
                <Stack flex={1}>
                    <Heading size="md">Nouveau match</Heading>
                    <Text mt="2px" fontSize="xs" _light={{ color: "primary.500" }} _dark={{ color: "primary.400" }} fontWeight="500">
                        {tprop.Match.label} {tprop.Match?.Program?.Learner?.firstName ? "pour " + tprop.Match?.Program?.Learner?.firstName : ""}
                    </Text>
                </Stack>
            </Box>

            {!firstLesson && (
                <Box flex={1}>
                    {latestExpiredLesson && (
                        <Alert variant="subtle" colorScheme="warning">{`Le 1er cours était prévu le ${DateTime.fromJSDate(latestExpiredLesson.startedAtPlanned!).toLocaleString(
                            DateTime.DATE_SHORT
                        )} mais tu n'as pas accepté dans les temps.\nUn conseiller va tenter de reprogrammer le 1er cours. Tu pourras ensuite accepter la proposition.`}</Alert>
                    )}
                    {!latestExpiredLesson && (
                        <Alert variant="subtle" colorScheme="warning">
                            Un conseiller va tenter de programmer un 1er cours. Tu pourras ensuite accepter la proposition.
                        </Alert>
                    )}
                </Box>
            )}

            {firstLesson && (
                <HStack space="10px" mt="10px">
                    {tprop.status === "PENDING" && firstLesson && (
                        <>
                            <Button variant="subtle2" colorScheme="brown" minWidth="100px" onPress={() => refuseDialog.open()}>
                                Refuser
                            </Button>
                            <Button variant="solid" colorScheme="pedagome" minWidth="100px" onPress={() => acceptDialog.open()}>
                                Accepter
                            </Button>
                            <Spacer />
                        </>
                    )}

                    <Box marginLeft="10px">
                        {collapseLogic.collapsed && <IconButton onPress={() => collapseLogic.expand()} mt="2px" variant="ghost" icon={<ChevronDownIcon size="md" color={iconColor} />} />}
                        {collapseLogic.expanded && <IconButton onPress={() => collapseLogic.collapse()} mt="2px" variant="ghost" icon={<ChevronUpIcon size="md" color={iconColor} />} />}
                    </Box>
                </HStack>
            )}

            {firstLesson && firstLesson?.startedAtPlanned && (
                <DetailSection title="1er cours prévu">
                    <Text>
                        {DateTime.fromJSDate(firstLesson.startedAtPlanned).toFormat("EEEE d MMMM 'de' H'h'mm").toLowerCase().replace("h00", "h")} à{" "}
                        {DateTime.fromJSDate(firstLesson.startedAtPlanned)
                            .plus({ minutes: firstLesson.durationMinPlanned ?? 0 })
                            .toFormat("H'h'mm")
                            .replace("h00", "h")}
                    </Text>
                </DetailSection>
            )}

            <Collapsible collapsed={collapseLogic.collapsed} /* style={{ maxWidth: useToken('breakpoints', '2xl') }} */>
                <DetailPanel propId={propId} />
            </Collapsible>

            <Text fontSize="xs" mt="5px" color="coolGray.600" _dark={{ color: "warmGray.200" }} fontWeight="400">
                {bottomInfoText}
            </Text>

            <Modal isOpen={acceptDialog.isOpen} size="lg">
                <Modal.Content>
                    <Modal.Header>
                        <Heading size="md">Acceptation match</Heading>
                    </Modal.Header>
                    <Modal.Body>
                        <Text mt="4px" fontSize="xs" _light={{ color: "primary.500" }} _dark={{ color: "primary.400" }} fontWeight="500">
                            {tprop.Match.label} {tprop.Match?.Program?.Learner?.firstName ? "pour " + tprop.Match?.Program?.Learner?.firstName : ""}
                        </Text>
                        {approxAddresses.map((adrStr, idx) => (
                            <Text key={idx}>{adrStr}</Text>
                        ))}
                        <Text>Rémunération : {formatEuro(tprop.baseRem)}</Text>

                        <Divider my="10px" />

                        <Heading size="md">1er cours</Heading>
                        <Text>
                            {firstLesson?.startedAtPlanned && DateTime.fromJSDate(firstLesson.startedAtPlanned).toFormat("EEEE d MMMM 'de' H'h'mm").toLowerCase().replace("h00", "h")} à{" "}
                            {firstLesson?.startedAtPlanned &&
                                DateTime.fromJSDate(firstLesson.startedAtPlanned)
                                    .plus({ minutes: firstLesson.durationMinPlanned ?? 0 })
                                    .toFormat("H'h'mm")
                                    .replace("h00", "h")}
                        </Text>

                        <Divider my="10px" />

                        <Heading size="md">Je m'engage à : </Heading>
                        <HStack alignItems="center" mt="5px">
                            <Box width="40px">
                                <FontAwesome5 name="hand-point-right" style={{ color: iconColor, fontSize: 20 }} />
                            </Box>
                            <Text flex={1} lineHeight="sm">
                                Contacter dès que possible l'élève en vue du 1er cours
                            </Text>
                        </HStack>
                        <HStack alignItems="center" mt="5px">
                            <Box width="40px">
                                <FontAwesome5 name="hand-point-right" style={{ color: iconColor, fontSize: 20 }} />
                            </Box>
                            <Text flex={1} lineHeight="sm">
                                Organiser les séances suivantes selon les besoins de l'élève
                            </Text>
                        </HStack>
                        <HStack alignItems="center" mt="5px">
                            <Box width="40px">
                                <FontAwesome5 name="hand-point-right" style={{ color: iconColor, fontSize: 20 }} />
                            </Box>
                            <Text flex={1} lineHeight="sm">
                                Informer immédiatement notre équipe en cas de problème concernant l'organisation, le déroulement ou la poursuite des cours
                            </Text>
                        </HStack>
                        <HStack alignItems="center" mt="5px">
                            <Box width="40px">
                                <FontAwesome5 name="hand-point-right" style={{ color: iconColor, fontSize: 20 }} />
                            </Box>
                            <Text flex={1} lineHeight="sm">
                                Déclarer les heures réalisées après chaque cours{"\n"}(dernier délai : minuit)
                            </Text>
                        </HStack>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button.Group space={2}>
                            <Button colorScheme="trueGray" variant="subtle2" onPress={acceptDialog.hide}>
                                Retour
                            </Button>
                            <Button colorScheme="pedagome" onPress={handleDialogSuccessAccept}>
                                Je m'engage
                            </Button>
                        </Button.Group>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>

            <Modal isOpen={refuseDialog.isOpen}>
                <Modal.Content>
                    <Modal.Body>
                        <Text>Es-tu certain de vouloir refuser définitivement cette proposition, pour ce cours et les suivants ?</Text>
                        <Text mt="4px" fontSize="xs" _light={{ color: "primary.500" }} _dark={{ color: "primary.400" }} fontWeight="500">
                            {tprop.Match.label} {tprop.Match?.Program?.Learner?.firstName ? "pour " + tprop.Match?.Program?.Learner?.firstName : ""}
                        </Text>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button.Group space={2}>
                            <Button colorScheme="trueGray" variant="subtle2" onPress={refuseDialog.hide}>
                                Annuler
                            </Button>
                            <Button colorScheme="brown" onPress={handleDialogSuccessRefuse}>
                                Refuser
                            </Button>
                        </Button.Group>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>
        </Card>
    )
}

function ExpiredPropositionCard({ propId }: { propId: number }) {
    const auth = useAuthContext()
    const { propositionsListQuery } = propositions_list_query.use({ teacherId: auth.userId })

    const tprop = propositionsListQuery.helpers.getPropById(propId)

    const handleDismiss = useCatchToBoundary(async () => {
        await openapi.propositions.dismissExpiredProposition(propId)
        await propositionsListQuery.refetch()
    }, [propId, propositionsListQuery])

    if (!tprop) return null
    const bottomInfoText = `Proposé ${toFrenchRelativeCalendar(DateTime.fromJSDate(tprop.sentAt!))}`

    return (
        <Card py="10px">
            <Box alignItems="center" width="full" justifyContent="center" display="flex" flexDirection="row">
                <Stack flex={1}>
                    <Heading size="md">Proposition expirée</Heading>
                    <Text mt="2px" fontSize="xs" _light={{ color: "primary.500" }} _dark={{ color: "primary.400" }} fontWeight="500">
                        {tprop.Match.label} {tprop.Match?.Program?.Learner?.firstName ? "pour " + tprop.Match?.Program?.Learner?.firstName : ""}
                    </Text>
                </Stack>
            </Box>

            <HStack space="15px" alignItems="center">
                <Text flex={1}>La proposition a été acceptée par un autre tuteur.</Text>

                <Button variant="subtle2" colorScheme="primary" minWidth="100px" onPress={() => handleDismiss()}>
                    Vu
                </Button>
            </HStack>

            <Text fontSize="xs" mt="5px" color="coolGray.600" _dark={{ color: "warmGray.200" }} fontWeight="400">
                {bottomInfoText}
            </Text>
        </Card>
    )
}

export function DetailPanel({ propId }: { propId: number }) {
    const auth = useAuthContext()
    const { propositionsListQuery } = propositions_list_query.use({ teacherId: auth.userId })
    const tprop = propositionsListQuery.helpers.getPropById(propId)
    const match = tprop?.Match
    const theme = useTheme()
    const iconColor = useColorModeValue(theme.colors.light[800], theme.colors.dark[800])

    return (
        <Box>
            {/* <DetailSection title="Durée totale">
                <HStack justifyContent="space-between" alignItems="center">
                    <Text fontSize="lg">{formatDurationMin(match?.lessonsDuration)}</Text>
                    <Button colorScheme="pedagome" variant="outline" onPress={() => navigation.getParent<NavigationProp<MainStackParamsList, "History">>().navigate('History', { matchId: match.id })} >Historique</Button>
                </HStack>
            </DetailSection> */}

            <DetailSection title={`${plural("Adresse approximative", "Adresses approximatives", match?.Program?.Learner?.LessonAddresses?.length ?? 0)}`}>
                <VStack space="15px">
                    {match?.Program?.Learner?.LessonAddresses?.map((address) => {
                        return (
                            <Box key={address.id} alignItems="center" width="full" justifyContent="center" display="flex" flexDirection="row">
                                <Text flex={1}>
                                    {`${address.streetTypeLabel} ${address.streetName} à ${address.cityName}`.replaceAll("  ", " ")}
                                    {address.role ? (
                                        <>
                                            {"\n"}({address.role})
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </Text>
                                <IconButton
                                    onPress={() =>
                                        openAddressInMaps({
                                            cityName: address.cityName,
                                            fullStreet: `${address.streetTypeLabel} ${address.streetName}`.replaceAll("  ", " "),
                                            postalCode: address.postalCode,
                                        })
                                    }
                                    mt="2px"
                                    /* variant={"ghost"} */ icon={<FontAwesome5 name="map-marked-alt" color={iconColor} size={18} />}
                                />
                            </Box>
                        )
                    })}
                </VStack>
                <Alert mt="10px" variant="subtle2" colorScheme="gray">
                    <Text colorScheme="gray">Les coordonnées exactes seront visibles après acceptation.{"\n"}L'élève doit aussi avoir finalisé son inscription.</Text>
                </Alert>
            </DetailSection>

            <DetailSection
                title="Scolarité"
                hide={Boolean(!match?.Program?.Learner?.scolarityClassLabel || (match?.Program?.Learner?.scolarityExpireOn && match?.Program?.Learner?.scolarityExpireOn < DayDate.today().toISOString()))}
            >
                <Text>
                    {match?.Program?.Learner?.scolarityClassLabel}
                    {conditional(` (${match?.Program?.Learner?.scolaritySchool})`, !!match?.Program?.Learner?.scolaritySchool)}
                </Text>
            </DetailSection>

            {!!match?.Program?.Learner?.birthDate && DateTime.fromISO(match.Program.Learner.birthDate).isValid && (
                <DetailSection title="Date de naissance" hide={!match.Program.Learner.birthDate}>
                    <Text>
                        {DateTime.fromISO(match.Program.Learner.birthDate).toFormat("MMMM yyyy").toLowerCase()} - {age(DayDate.fromISOString(match.Program.Learner.birthDate)!)}
                    </Text>
                </DetailSection>
            )}

            <DetailSection title="Objectifs" hide={!match?.Program?.goals}>
                <Text>{match?.Program?.goals}</Text>
            </DetailSection>

            <DetailSection title="Moyenne" hide={!match?.Program?.initialMean}>
                <Text>{match?.Program?.initialMean?.toFixed(0)}</Text>
            </DetailSection>

            {match?.Program?.wishedDurationMin && match?.Program?.wishedFrequency && (
                <DetailSection title="Durée et fréquence souhaitée (a titre indicatif)" hide={!match?.Program?.wishedDurationMin || !match?.Program?.wishedFrequency}>
                    <Text>
                        {formatDurationMin(match?.Program?.wishedDurationMin ?? 0)} {!isNil(match?.Program.wishedFrequency) && frequencyToText(match?.Program.wishedFrequency ?? 0)}
                    </Text>
                </DetailSection>
            )}

            <DetailSection title="Rémunération">
                <Text>{formatEuro(tprop?.baseRem ?? 0)} /h</Text>
            </DetailSection>

            <DetailSection title="Commentaires" hide={!tprop?.teacherInstructions}>
                <Text>{tprop?.teacherInstructions}</Text>
            </DetailSection>
        </Box>
    )
}

export function MatchWithoutFirstLessonCard({ matchId }: { matchId: number }) {
    const auth = useAuthContext()
    const { matchInfosListQuery } = match_infos_list_query.use({ teacherId: auth.userId })
    const match = matchInfosListQuery.helpers.findById(matchId)

    const latestExpiredLesson = { startedAtPlanned: new Date() }
    if (!match) return null

    //TODO: ce n'est pas forcément qu'il a expiré. Il a pu être annulé, refusé ...
    //Il faut se concentrer sur faire un message de "Le conseiller va tenter de reprogrammer"
    //NON EN FAIT NON : En cas d'annulation le conseiller ne s'en mèele plus.
    //Une fois qu'il y a un créneau BOOKED, CANCELLED, SUBMITTED ou PAYMSLIPPED, on affiche pas de message.
    //Ce qui revient a simplement exclure les matchs ACTIFS
    //On veut seulement les matchs DRAFT, attribués au prof, et sans 1er créneau
    return (
        <Card py="10px">
            <Box alignItems="center" width="full" justifyContent="center" display="flex" flexDirection="row">
                <Stack flex={1}>
                    <Heading size="md">1er cours à reprogrammer</Heading>
                    <Text mt="2px" fontSize="xs" _light={{ color: "primary.500" }} _dark={{ color: "primary.400" }} fontWeight="500">
                        {match.label} {match?.Program?.Learner?.firstName ? "pour " + match?.Program?.Learner?.firstName : ""}
                    </Text>
                </Stack>
            </Box>

            <Box flex={1}>
                {latestExpiredLesson && <Alert variant="subtle" colorScheme="warning">{`Un conseiller va tenter de reprogrammer le 1er cours. Tu pourras ensuite l'accepter.`}</Alert>}
                {/* {!latestExpiredLesson && <Alert variant="subtle" colorScheme="warning">Un conseiller va tenter de programmer un 1er cours. Tu pourras ensuite accepter la proposition.</Alert>} */}
            </Box>
        </Card>
    )
}

interface DetailSectionProps {
    title: string
    children: ReactNode | ReactNode[]
    hide?: boolean
    noDivider?: boolean
}
function DetailSection(props: DetailSectionProps) {
    const { children, title, hide, noDivider } = props
    const color = useColorModeValue("light.600", "dark.600")
    if (hide) return null
    return (
        <>
            {!noDivider && <Divider my="15px" />}

            <Text fontSize="xs" mb="3px" color={color}>
                {title}
            </Text>
            {children}
        </>
    )
}
