import { FastModal } from "@core/atoms/FastModal"
import { useAuthContext } from "@core/context/auth"
import { FmkComboBox } from "@core/formik/FmkComboBox"
import type { DialogAwaitLogic, DialogLogic } from "@core/hooks/useDialog"
import { useValidationSchema } from "@core/hooks/useValidationSchema"
import { current_real_lessons_query, match_history_query, match_infos_list_query, user_info_query } from "@core/queries"
import { openapi } from "@core/services/openapi"
import { EuropeParis, getShowedMonthDt } from "@core/utils"
import { FmkUtils } from "@core/utils/formik"
import { FontAwesome5 } from "@expo/vector-icons"
import { SubmitButton } from "@native-base/formik-ui"
import { Formik } from "formik"
import { capitalize, min } from "lodash"
import { DateTime, Duration } from "luxon"
import { Box, Button, Heading, Icon, Modal, Text } from "native-base"
import { useMemo } from "react"
import { useErrorBoundary } from "react-error-boundary"

const durationOptions = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4]
    .map((hour) => hour * 60)
    .map((minute) => ({
        label: Duration.fromObject({ minutes: minute }).toFormat("h'h'mm").replace("0h30", "30min").replace("h00", "h"),
        value: minute,
    }))

export class FormValues {
    @FmkUtils.DefField day: number | null
    @FmkUtils.DefField durationMin: number | null
}

const FIELDS = FmkUtils.getFields(FormValues)

export interface AddLessonModalProps {
    dialogLogic: DialogAwaitLogic
    matchId: number
}

export function AddLessonModal(props: AddLessonModalProps) {
    const { dialogLogic, matchId } = props

    const schema = useValidationSchema(
        (Yup) =>
            Yup.object().shape({
                [FIELDS.day]: Yup.number().nullable().required("Requis").min(1, "Requis"),
                [FIELDS.durationMin]: Yup.number().nullable().required("Requis").min(0.5, "Requis"),
            }),
        []
    )

    const auth = useAuthContext()
    const teacherId = auth.userId

    const { matchInfosListQuery } = match_infos_list_query.use({ teacherId })
    const { currentRealLessonsQuery } = current_real_lessons_query.use({ teacherId })
    const { userInfoQuery } = user_info_query.use({ teacherId: auth.userId })
    const monthDt = getShowedMonthDt()
    const monthStr = monthDt.toISODate()
    const matchInfo = matchInfosListQuery.helpers.findById(matchId)
    // const matchLessons = currentRealLessonsQuery.data?.findByMatchId(matchId)

    //soit 7j après acceptation, soit jusqu'au 31dec
    const newProcessAfterDateStr = min([
        DateTime.fromJSDate(userInfoQuery.data?.newProcessEnabledAt ?? new Date(0))
            .plus({ days: 7 })
            .toISODate(),
        "2023-01-01",
    ])!
    const isNewProcess = DateTime.now().setZone(EuropeParis).toISODate()! >= newProcessAfterDateStr
    const isOldProcess = !isNewProcess

    const { showBoundary } = useErrorBoundary()

    const currentDay = DateTime.now().day.valueOf()

    const dayOptions = useMemo(() => {
        if (!isOldProcess) return []

        return Array.from("x".repeat(currentDay))
            .map((x, index) => index + 1)
            .map((day) => ({
                label: `${monthDt.set({ day }).toFormat("EEE d")}`,
                value: day,
            }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOldProcess, currentDay, monthStr])

    return (
        <FastModal isOpen={dialogLogic.isOpen}>
            <Formik<FormValues>
                initialValues={{
                    durationMin: null,
                    day: DateTime.now().day,
                }}
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        await auth.ensureValidToken()
                        const newDbLesson = await openapi.teachersApp.submitFastLesson({
                            startedAtOfficial: isOldProcess ? monthDt.set({ day: values.day! }).toJSDate() : new Date(),
                            durationMin: values.durationMin!,
                            matchId,
                            teacherId: teacherId!,
                        })

                        //refetch base query
                        await currentRealLessonsQuery.refetch()

                        //invalidate other impacted queries
                        currentRealLessonsQuery.queryClient.invalidateQueries(match_history_query.getKey({ matchId }))
                        currentRealLessonsQuery.queryClient.invalidateQueries(match_infos_list_query.getKey({ teacherId }))

                        dialogLogic.hide("success")
                    } catch (err) {
                        showBoundary(err)
                    } finally {
                        setSubmitting(false)
                    }
                }}
            >
                <Modal.Content width="350px">
                    {/* <Modal.CloseButton /> */}
                    <Modal.Header>
                        <Heading size="md">Déclaration cours</Heading>
                    </Modal.Header>
                    <Modal.Body>
                        <Text fontSize="md">
                            {matchInfo?.Program?.Learner?.firstName} {matchInfo?.Program?.Learner.lastName}
                        </Text>
                        <Text color="primary.500">{matchInfo?.label}</Text>

                        {isOldProcess ? (
                            <FmkComboBox _formControl={{ mt: 3 }} name={FIELDS.day} label="Date" modalHeader={monthDt.toFormat("MMMM yyyy")} clearable options={dayOptions} configure={(opt) => opt} />
                        ) : (
                            <Text>{capitalize(DateTime.now().toFormat("EEEE dd MMMM"))}</Text>
                        )}

                        <FmkComboBox _formControl={{ mt: "3" }} name={FIELDS.durationMin} label="Durée" modalHeader="Durée" clearable options={durationOptions} configure={(opt) => opt} />

                        {isOldProcess && (
                            <Box mt="15px">
                                <Text color="danger.500">
                                    <Icon as={FontAwesome5} name="exclamation-circle" color="danger.500" mr={1} />A partir du{" "}
                                    {DateTime.fromISO(newProcessAfterDateStr).setZone(EuropeParis).minus({ day: 1 }).toFormat("d MMMM").toLowerCase()}, il ne sera plus possible de saisir des heures rétroactivement. Elles
                                    devront être saisie le jour même.
                                </Text>
                            </Box>
                        )}

                        <Box mt={2}>
                            <Text>
                                <Icon as={FontAwesome5} name="exclamation-triangle" color="warning.500" mr={1} />
                                Saisie définitive après un délai de 12h.
                            </Text>
                        </Box>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button.Group space={2}>
                            <Button variant="subtle2" colorScheme="trueGray" onPress={() => dialogLogic.hide("cancel")}>
                                Annuler
                            </Button>

                            <SubmitButton colorScheme="pedagome">Enregistrer</SubmitButton>
                        </Button.Group>
                    </Modal.Footer>
                </Modal.Content>
            </Formik>
        </FastModal>
    )
}
