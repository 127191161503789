import { useAuthContext } from "@core/context/auth"
import { monthly_match_surveys_query } from "@core/queries/monthly_match_surveys"
import { sleep } from "@core/utils/tools"
import { FontAwesome5, MaterialIcons } from "@expo/vector-icons"
import type { MaterialTopTabNavigationProp } from "@react-navigation/material-top-tabs"
import { useNavigation } from "@react-navigation/native"
import { Badge, Box, Button, HStack, Text, useBreakpointValue, useColorModeValue, useTheme, useToken } from "native-base"
import React, { useEffect, useRef } from "react"
import { Animated, Pressable } from "react-native"
// import usePrevious from "./usePrevious"

import type { MainTabsParamList } from "../MainStack"
import { currentScreenStore } from "./currentScreenStore"
// import { usePrevious } from '@core/hooks'

function TabContent({ focused, iconName, label, IconBank }: { focused: boolean; label: string; iconName: string; IconBank: any }) {
    const [light800, dark800, light400, dark400] = useToken("colors", ["light.800", "dark.800", "light.400", "dark.400"])

    const focuedIconColor = useColorModeValue(light800, dark800)
    const unfocusedIconColor = useColorModeValue(light400, dark400)
    return (
        <HStack space={2} alignItems="center">
            <Box width={22}>
                <IconBank name={iconName} size={18} color={focused ? focuedIconColor : unfocusedIconColor} />
                {/* <Icon as={FontAwesome5} name={iconName} size={18} color={focused ? focuedIconColor : unfocusedIconColor} /> */}
            </Box>
            <Text color={focused ? focuedIconColor : unfocusedIconColor}>{label}</Text>
        </HStack>
    )
}

const percent = (val: number) => `${Math.round(val)}%`

type TabConfig = { name: keyof MainTabsParamList; iconName: string; IconBank: any; title: string; getBadge?: (record: Record<string, any>) => any }

const tabsList: TabConfig[] = [
    {
        name: "SaisieHeures",
        title: "Heures",

        // iconName: "more-time",
        // IconBank: MaterialIcons,
        iconName: "calendar-alt",
        IconBank: FontAwesome5,
    },
    {
        name: "SurveysList",
        title: "Bilans",
        iconName: "file-signature",
        IconBank: FontAwesome5,
        getBadge: (record) => record?.pendingSurveysCount,
    },
    {
        name: "MatchList",
        title: "Matchs",
        iconName: "handshake",
        IconBank: FontAwesome5,
    },
    {
        name: "User",
        title: "Profil",
        iconName: "user-cog",
        IconBank: FontAwesome5,
    },
]

export interface FakeTabsProps {
    // current: string
}
export function FakeTabs(props: FakeTabsProps) {
    // const { current } = props

    // const inputRange = props.navigationState.routes.map((x, i) => i);

    const currentScreen = currentScreenStore.useStoreState((x) => x.name)

    const navigation = useNavigation<MaterialTopTabNavigationProp<MainTabsParamList>>()
    // const route = useRoute<RouteProp<MainTabsParamList>>()

    const auth = useAuthContext()
    const { monthlyMatchSurveysQuery } = monthly_match_surveys_query.use({ status: "DRAFT", teacherId: auth.userId })
    const pendingSurveysCount = monthlyMatchSurveysQuery.helpers.getPendingSurveysCount()

    const theme = useTheme()
    const appBarBackground = useColorModeValue(theme.colors.appBar.light, theme.colors.appBar.dark)
    const borderColorUnselected = useColorModeValue("coolGray.200", "gray.400")
    const tabBarPosition: "bottom" | "top" = useBreakpointValue({ base: "bottom", md: "top" })

    return (
        <Box flexDirection="row" flexGrow={1} maxWidth={tabBarPosition === "top" ? 500 : null} overflowX="hidden">
            {/* <TestAnimation/> */}

            <AnimatedIndicator />

            {tabsList.map((tabConfig) => {
                const isCurrent = tabConfig.name === currentScreen //route.name

                // const opacity = props.position.interpolate({
                //   inputRange,
                //   outputRange: inputRange.map(inputIndex => inputIndex === i ? 1 : 0.5)
                // });

                // const borderColor = isCurrent ? theme.colors.primary[500] : borderColorUnselected

                const badgeVal = tabConfig.getBadge?.({ pendingSurveysCount })

                return (
                    <Pressable
                        key={tabConfig.name}
                        onPress={() => {
                            // doSlide()
                            navigation.navigate(tabConfig.name)
                        }}
                        style={{ flexGrow: 1, position: "relative" }}
                    >
                        <Box borderBottomWidth="3" borderColor={borderColorUnselected} flex={1} alignItems="center" p="3" padding="10px" backgroundColor={appBarBackground} justifyContent="center">
                            {badgeVal ? (
                                <Badge rounded="full" variant="subtle" right="5px" top="1px" position="absolute" colorScheme="pedagome">
                                    {badgeVal}
                                </Badge>
                            ) : null}
                            <TabContent iconName={tabConfig.iconName} IconBank={tabConfig.IconBank} label={tabConfig.title} focused={isCurrent} />
                        </Box>
                    </Pressable>
                )
            })}
        </Box>
    )
}

const AnimatedIndicator = React.memo(() => {
    // const currentScreen = currentScreenStore.useStoreState(x => x.name)

    const slotWidthPercent = 100 / tabsList.length
    // const currentSlotIndex = tabsList.findIndex(tab => tab.name === currentScreen)
    // const currentSlotLeft = Math.round(currentSlotIndex * slotWidthPercent)
    // const currentSlotRight = Math.round(100 - currentSlotLeft - slotWidthPercent)

    // const slideAnim = useRef(new Animated.Value(0)).current
    const growAnim = useRef(new Animated.ValueXY({ x: 0, y: 75 })).current

    // const doSlide = ()=>{

    // }

    // const animRunning = useRef(false)
    // const previouCurrentSlotLeft = usePrevious(currentSlotLeft)

    const anmation1PromiseRef = useRef<Promise<any> | undefined>()
    const anmation2RunningRef = useRef(false)
    const sequenceStaredAt = useRef(0)
    currentScreenStore.useWatch(
        (prev, next) => prev.isChanging !== next.isChanging && next.isChanging === true,
        async (next) => {
            // if (anmation1RunningRef.current || anmation2RunningRef.current) {
            //     return
            // }

            // anmation1RunningRef.current = true

            // Animated.timing(growAnim, {
            //     duration: 0,
            //     toValue: { x: 0, y: 0 },
            //     useNativeDriver: false
            // }).start(() => {
            //     // resolve(true)
            //     // anmation1PromiseRef.current = undefined
            //     anmation1RunningRef.current = false
            //     // animRunning.current = false
            // });

            if (anmation1PromiseRef.current || anmation2RunningRef.current) {
                return
            }

            anmation1PromiseRef.current = new Promise((resolve) => {
                console.debug("isChanging", next.isChanging)
                Animated.timing(growAnim, {
                    duration: 0,
                    toValue: { x: 0, y: 0 },
                    useNativeDriver: false,
                }).start(() => {
                    sequenceStaredAt.current = Date.now()
                    resolve(true)
                })
            }).then(async () => {
                await sleep(300)
                anmation1PromiseRef.current = undefined
            })
        }
    )

    //arrêt swipe sur la même page
    currentScreenStore.useWatch(
        (prev, next) => prev.isChanging !== next.isChanging && next.isChanging === false && prev.name === next.name,
        async (next) => {
            console.debug("isChanging", next.isChanging)

            // if (anmation1RunningRef.current) {
            //     return
            // }

            if (anmation2RunningRef.current) {
                return
            }

            if (anmation1PromiseRef.current) {
                await anmation1PromiseRef.current
            }

            if (Date.now() - sequenceStaredAt.current < 1500) {
                await sleep(1500 - (Date.now() - sequenceStaredAt.current))
            }

            const currentSlotIndex = tabsList.findIndex((tab) => tab.name === currentScreenStore.getState().name)
            const currentSlotLeft = Math.round(currentSlotIndex * slotWidthPercent)
            const currentSlotRight = Math.round(100 - currentSlotLeft - slotWidthPercent)

            Animated.spring(growAnim, {
                // duration: 1000,
                speed: 3,
                bounciness: 10,
                toValue: { x: currentSlotLeft, y: currentSlotRight },
                useNativeDriver: false,
            }).start(() => {
                anmation2RunningRef.current = false
                sequenceStaredAt.current = 0
                // animRunning.current = false
            })
        }
    )

    currentScreenStore.useWatch(
        (prev, next) => prev.name !== next.name,
        async (next) => {
            console.debug("isChanging", next.isChanging)

            // if (anmation1RunningRef.current) {
            //     return
            // }

            if (anmation2RunningRef.current) {
                return
            }

            if (anmation1PromiseRef.current) {
                await anmation1PromiseRef.current
            }

            const currentSlotIndex = tabsList.findIndex((tab) => tab.name === next.name)
            const currentSlotLeft = Math.round(currentSlotIndex * slotWidthPercent)
            const currentSlotRight = Math.round(100 - currentSlotLeft - slotWidthPercent)

            Animated.spring(growAnim, {
                // duration: 1000,
                speed: 3,
                bounciness: 10,
                toValue: { x: currentSlotLeft, y: currentSlotRight },
                useNativeDriver: false,
            }).start(() => {
                anmation2RunningRef.current = false
                // animRunning.current = false
            })
        }
    )

    // currentScreenStore.useWatch((prev, next) => prev.name !== next.name, (next) => {
    //     console.debug('isChanging', next.isChanging)
    //         Animated.timing(growAnim, {
    //             duration: 600,
    //             toValue: 0,
    //             useNativeDriver: false,
    //             isInteraction:true
    //         }).start(() => {
    //             // animRunning.current = false
    //         }) ;
    // })

    // useEffect(() => {

    //     const hasSlotChanged = previouCurrentSlotLeft !== currentSlotLeft
    //     if(!hasSlotChanged){
    //         return
    //     }

    //     if (animRunning.current) {
    //         console.debug('animation already running')
    //         // return
    //     }
    //     console.debug('doSlide from ' + growAnim + " to " + currentSlotLeft)
    //     animRunning.current = true

    //     Animated.timing(growAnim, {
    //         duration: 600,
    //         toValue: 0,
    //         useNativeDriver: false
    //     }).start(() => {
    //         animRunning.current = false
    //     });

    //     // Animated.timing(slideAnim, {
    //     //     duration: 600,
    //     //     toValue: currentSlotLeft,
    //     //     useNativeDriver: false
    //     // }).start(() => {
    //     //     animRunning.current = false
    //     // });

    // }, [currentSlotLeft])

    return (
        <Animated.View
            nativeID="selected-indicator"
            style={[
                { position: "absolute", bottom: 0, borderBottomColor: "red", borderBottomWidth: 3, zIndex: 1 },
                {
                    left: growAnim.x.interpolate({ inputRange: [0, 100], outputRange: ["0%", "100%"] }),
                    right: growAnim.y.interpolate({ inputRange: [0, 100], outputRange: ["0%", "100%"] }),
                },
            ]}
        />
    )
    // left: slideAnim.interpolate({ inputRange: [0, 100], outputRange: ['0%', '100%'] })
})

//file-signature
//feather

//business-time
//calendar-alt
//calendar-plus

function TestAnimation() {
    // fadeAnim will be used as the value for opacity. Initial Value: 0
    const fadeAnim = useRef(new Animated.Value(0)).current

    const fadeIn = () => {
        // Will change fadeAnim value to 1 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 5000,
            useNativeDriver: false,
        }).start()
    }

    const fadeOut = () => {
        // Will change fadeAnim value to 0 in 3 seconds
        Animated.timing(fadeAnim, {
            toValue: 0,
            duration: 3000,
            useNativeDriver: false,
        }).start()
    }

    return (
        <Box>
            <Animated.View
                style={[
                    { height: 200, width: 200, backgroundColor: "blue" },
                    {
                        // Bind opacity to animated value
                        opacity: fadeAnim,
                    },
                ]}
            >
                <Text>Fading View!</Text>
            </Animated.View>
            <HStack>
                <Button children="Fade In View" onPress={fadeIn} />
                <Button children="Fade Out View" onPress={fadeOut} />
            </HStack>
        </Box>
    )
}

// <MainTabsNav.Screen
// name="Saisie"
// component={SaisieScreen}
// options={{
//   title: `Heures du mois - ${capitalize(DateTime.now().toFormat('MMMM yyyy'))}`,
//   tabBarLabel: ({ focused }) => <TabContent focused={focused} iconName="more-time"  IconBank={MaterialIcons} label="Saisie" />
// }}
// />
// <MainTabsNav.Screen
// name="Bilans"
// component={BilansScreen}
// options={{
//   title: `tdryfgubhinjl,km;`,
//   tabBarLabel: ({ focused }) => <TabContent focused={focused} iconName="edit"  IconBank={FontAwesome5} label="Bilans" />
// }}
// />
// <MainTabsNav.Screen
// name="MatchList"
// component={MatchListScreen}
// options={{
//   title: "Liste des matchs",
//   tabBarBadge: () => { return pendingPropositionsCount ? <Badge rounded="full" variant="solid" mt="5px" mr="15px">{pendingPropositionsCount}</Badge> : null },
//   tabBarLabel: ({ focused }) => <TabContent focused={focused} iconName="handshake"  IconBank={FontAwesome5} label="Matchs" />
// }}
// />
// <MainTabsNav.Screen
// name="User"
// component={UserScreen}
// options={{
//   title: "Profil",
//   tabBarLabel: ({ focused }) => <TabContent focused={focused} iconName="user"  IconBank={FontAwesome5} label="Profil" />
// }}
// />

//cursor="pointer"
