import "react-native-url-polyfill/auto" //empêche l'erreur 'Not implemented' causée lorsque le code généré d'openapi utilise URL.xxx
import { DOPPLE_API_URL } from "@core/config/api"
import { getAxiosRetryInstance } from "@core/config/axios-retry"
import { AxiosWithInterceptors } from "@core/utils/axiosWithInterceptors"
import type { AxiosResponse } from "axios"

import { authHeader } from "./authHeader"
import { MainApi } from "./swaggerClient"

//1. error is intercepted before throw in axios
//2. error is passed to apiErrHandler to run some side effects (snackbar, loginpage)
//3. error is thrown to stop the execution of the calling thunk
//(handle here only error types that are common to all app - eg INVALID-PASSWORD should be handled directly in the login thunk)

export const openapiAxios = new AxiosWithInterceptors()

    //merge default headers and token header
    .setHeaderFn(async (configHeaders) => {
        return { ...configHeaders, ...(await authHeader()) }
    })
    .configureInstance(getAxiosRetryInstance())

const isJsonMime = (mime: string) => mime.includes("json")
export const openapi = new MainApi({ isJsonMime }, DOPPLE_API_URL, openapiAxios) as unknown as RecursiveUnpackService<MainApi>

//as we use an interceptor to return res.data instead of res, we need to modify the type of the services.
type UnpackService<S extends (...args: any) => any> = (...args: Parameters<S>) => ReturnType<S> extends Promise<AxiosResponse<infer R>> ? Promise<R> : ReturnType<S>

type RecursiveUnpackService<API> = {
    [K in keyof API]: API[K] extends (...args: any) => any ? UnpackService<API[K]> : API[K] extends Record<string, any> ? RecursiveUnpackService<API[K]> : API[K]
}
