import { produce, type Draft } from "immer"
import { type DependencyList, useCallback, useEffect, useSyncExternalStore } from "react"

type Listener = () => void
type Watcher<S> = (previousState: S, newState: S) => boolean
type ChangeHandler<S> = (newState: S) => any
export class ExtenalStore<S> {
    constructor(initialValue: S) {
        this.state = initialValue
    }
    private state: S
    private listeners = new Set<Listener>()
    private watchers = new Map<Watcher<S>, ChangeHandler<S>>()

    private suscribe(listener: Listener) {
        this.listeners.add(listener)
        return () => {
            this.listeners.delete(listener)
        }
    }
    public getState() {
        return this.state
    }

    private setState(nextState: S) {
        const previousState = this.state
        this.state = nextState
        this.listeners.forEach((listener) => listener())
        this.watchers.forEach((onChange, watcher) => {
            watcher(previousState, nextState) && onChange(nextState)
        })
        console.debug("setting state" + this.watchers.size)
    }
    public mutateState(mutateStateFn: (oldState: Draft<S>) => void) {
        const nextState = produce(this.state, (draft) => mutateStateFn(draft))
        this.setState(nextState)
    }

    public mergeState(newState: Partial<S>) {
        const nextState = produce(this.state ?? {}, (draft) => {
            Object.assign(draft, newState)
        })
        this.setState(nextState as S)
    }

    public useStoreState(): S
    public useStoreState<R>(selector: (state: S) => R, selectorDeps?: DependencyList): R
    public useStoreState(selector = (s: S) => s, selectorDeps: DependencyList = []) {
        //eslint-disable-next-line
        return useSyncExternalStore(
            (listener: Listener) => this.suscribe(listener),
            //eslint-disable-next-line
            useCallback(() => selector(this.getState()), [selectorDeps])
        )
    }

    public useWatch(watcher: Watcher<S>, onChange: ChangeHandler<S>) {
        //eslint-disable-next-line
        useEffect(() => {
            this.watchers.set(watcher, onChange)

            return () => {
                this.watchers.delete(watcher)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])
    }
}
