import type { GlobStackParamsList } from "@core/config/Navigator"
import { useAuthContext } from "@core/context/auth"
import { type NavigationProp, useNavigation } from "@react-navigation/native"
import { createStackNavigator } from "@react-navigation/stack"
import React, { useEffect } from "react"

import { ForgetPasswordScreen } from "./ForgetPasswordScreen"
import { LoginScreen } from "./LoginScreen"

export type AuthStackParamList = {
    Login: { login?: string; password?: string }
    ForgetPassword: { email: string }
    // LoginReset: { login?: string; password?: string }
}

const AuthStackNav = createStackNavigator<AuthStackParamList>()
export function AuthStack() {
    // return <Text>AuthStack</Text>

    const auth = useAuthContext()

    const navigation = useNavigation<NavigationProp<GlobStackParamsList>>()

    useEffect(() => {
        if (auth.isLoggedIn) {
            navigation.navigate("MainStack")
        }
    })

    return (
        <AuthStackNav.Navigator
            screenOptions={{
                headerShown: false,
            }}
        >
            {/* <AuthStack.Screen name="Login" component={Login} /> */}

            <AuthStackNav.Screen name="Login" component={LoginScreen} />
            <AuthStackNav.Screen name="ForgetPassword" component={ForgetPasswordScreen} />

            {/* <AuthStackNav.Screen name="LoginReset" component={LoginResetScreen} /> */}
        </AuthStackNav.Navigator>
    )
}
