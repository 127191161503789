import type { GlobStackParamsList } from "@core/config/Navigator"
import { useAuthContext } from "@core/context/auth"
import { useCatchToBoundary, useSpinner } from "@core/hooks"
import { ToggleDarkMode } from "@core/misc-components/ToggleDarkMode"
import { openapi } from "@core/services/openapi"
import { isApiError } from "@core/utils/errors"
import { type NavigationProp, type RouteProp, useRoute } from "@react-navigation/native"
import type { StackScreenProps } from "@react-navigation/stack"
import { Box, Button, Center, Flex, FormControl, Heading, Image, Input, Text, VStack } from "native-base"
import React, { useCallback, useEffect, useRef, useState } from "react"

import type { AuthStackParamList } from "./AuthStack"

export function LoginScreen({ navigation }: StackScreenProps<AuthStackParamList, "Login">) {
    const { params } = useRoute<RouteProp<AuthStackParamList, "Login">>()
    return <LoginScreenContent goToForgetPassword={({ email }) => navigation.navigate("ForgetPassword", { email })} type="page" params={params} navigation={navigation} />
}

export function LoginScreenContent({
    goToForgetPassword,
    type,
    params = {},
    navigation,
}: {
    goToForgetPassword: (params: { email: string }) => any
    type: "page" | "dialog"
    params?: AuthStackParamList["Login"]
    navigation?: NavigationProp<AuthStackParamList>
}) {
    const [email, setEmail] = useState<string>("")

    const [emailTouched, setEmailTouched] = useState(false) //default touched to enable button
    const [passwordTouched, setPasswordTouched] = useState(false)
    const formTouched = emailTouched || passwordTouched

    const auth = useAuthContext() //?? authCtx
    // if(!auth ) throw new Error('auth is ' + auth + ' in ' + type)
    // console.debug('LoginScreenContent -> auth is ' + (auth ? 'ok' : auth) +  ' in ' + type + ' at ' + Math.round((new Date().valueOf() - new Date('2022-11-29T15:27:00').valueOf()) / 100))
    // console.log({ params, ref: auth.autofillRef.current })

    const [password, setPassword] = useState<string>("")

    const autofillledRef = useRef(false)
    useEffect(() => {
        // console.log("effect start", { ref: auth.autofillRef.current, filled: autofillledRef.current })
        if (params.login && params.password && autofillledRef.current === false) {
            setEmail(params.login)
            setPassword(params.password)
            autofillledRef.current = true
            // console.log("recieved route params : ", params)
        } else if (auth.autofillRef.current?.email && auth.autofillRef.current?.password && autofillledRef.current === false) {
            // console.log("recieved ref params : ", params)
            setEmail(auth.autofillRef.current?.email)
            setPassword(auth.autofillRef.current?.password)
            auth.autofillRef.current = null
            autofillledRef.current = true
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.login, params.password, autofillledRef])

    const [errorType, setErrorType] = useState<"unkown-user" | "invalid-password" | null>(null)

    const spinner = useSpinner()

    const login = useCatchToBoundary(async () => {
        if (!(email && password)) {
            return
        }

        try {
            const token = await openapi.teachersApp.signin({ email, password })
            auth.setToken(token)
            // //if the login page is displayed as the page /login, we redirect to previous page (or home)
            // //if it is ispayed as a modal before an api call, we don't redirect
            // if (token && location.pathname === "/login") {
            //   redirectToPreviousOrHome()
            // }
            // //open as dialog
            // else if (token && location.pathname !== "/login") {
            //   auth.hideLoginDialog()
            // }

            if (auth.isLoginDialogOpen) {
                auth.hideLoginDialog()
            } else {
                navigation?.getParent<NavigationProp<GlobStackParamsList>>().reset({
                    index: 0,
                    routes: [{ name: "MainStack" }],
                })

                // navigation?.getParent<NavigationProp<GlobStackParamsList>>().navigate('MainStack')
                // navigation && console.log("glob navigator reset from LoginScreen")
            }

            setErrorType(null)
        } catch (err) {
            if (isApiError("UnknownUser", err)) {
                setErrorType("unkown-user")
            } else if (isApiError("InvalidPassword", err)) {
                setErrorType("invalid-password")
            } else {
                throw err
            }
        } finally {
            spinner.stop()
            setEmailTouched(false)
            setPasswordTouched(false)
        }
    })

    const hasMissingFields = !email || !password
    const hasErrors = Boolean(errorType)

    const handleEmailChange = useCallback((text: string) => {
        setEmail(text)
        setEmailTouched(true)
    }, [])

    const handlePasswordChange = useCallback((text: string) => {
        setPassword(text)
        setPasswordTouched(true)
    }, [])

    console.debug({ hasMissingFields, hasErrors, formTouched, email, password })

    return (
        <Center w="100%" h="100%" safeArea>
            <Box p="2" py="8" w="100%" maxW="290">
                <Image source={require("../assets/images/login.png")} alt="login-illustration" size="xl" alignSelf="center" />

                <Heading size="lg" p="30" fontWeight="600" color="coolGray.800" _dark={{ color: "warmGray.50" }} alignSelf="center">
                    Bienvenue
                </Heading>

                <VStack space={3} mt="0">
                    <FormControl>
                        <FormControl.Label>Email</FormControl.Label>
                        <Input
                            value={email}
                            autoCapitalize="none"
                            // autoCompleteType="off"
                            autoCorrect={false}
                            keyboardType="email-address"
                            onChangeText={handleEmailChange}
                        />
                        {errorType === "unkown-user" && !emailTouched && (
                            <Text color="error.500" fontSize="xs" alignSelf="flex-start" mt="1">
                                email inconnu
                            </Text>
                        )}
                    </FormControl>

                    <FormControl>
                        <FormControl.Label _text={{ fontFamily: "WorkSans_600SemiBold" }}>Mot de passe</FormControl.Label>
                        <Input type="password" value={password} onChangeText={handlePasswordChange} />

                        <Flex justify="space-between" direction="row">
                            <Text color="error.500" fontSize="xs" mt="1">
                                {errorType === "invalid-password" && !passwordTouched ? "mdp incorrect" : ""}
                            </Text>

                            <Button variant="link" _text={{ fontSize: "xs", fontWeight: "500", color: "primary.500" }} mt="0" onPress={() => goToForgetPassword({ email })}>
                                Mot de passe oublié ?
                            </Button>
                        </Flex>
                    </FormControl>

                    <Button
                        isDisabled={(hasMissingFields && formTouched) || (hasErrors && !formTouched)}
                        mt="2"
                        colorScheme="pedagome"
                        _text={{ color: "lightText" }}
                        onPress={() => login()}
                        _loading={{ color: "pedagome.500", backgroundColor: "pedagome.400" }}
                        isLoading={spinner.loading}
                        isLoadingText="Connexion"
                    >
                        {type === "dialog" ? "Se reconnecter" : "Connexion"}
                    </Button>
                </VStack>
            </Box>

            <Center>
                <ToggleDarkMode />
            </Center>
        </Center>
    )
}
