import type { GlobStackParamsList } from "@core/config/Navigator"
import { useAuthContext } from "@core/context/auth"
import { useCatchToBoundary, usePrevious } from "@core/hooks"
import { user_info_query } from "@core/queries"
import { rasmik, Teacher } from "@core/services/rasmik"
import type { NavigationProp } from "@react-navigation/native"
import { useColorModeValue, useTheme } from "native-base"
import { useEffect, useState } from "react"

import type { MainStackParamsList } from "../../MainStack"
import type { Day, DraftWeekDispos } from "../EditWeekDispoForm"
import type { Time } from "./TimePicker"
import { getIdFromDay, isDispoArrayChanged, prepareDispoData, removeNegativeIds, validateDispoInput } from "./utils"

export type EditWeekDispoFormLogic = ReturnType<typeof useEditWeekDispoForm>
export function useEditWeekDispoForm({ displayAppBar, navigation: mainStackNavigation }: { displayAppBar: boolean; navigation: NavigationProp<MainStackParamsList> }) {
    const [isSubmitting, setSubmitting] = useState<boolean>(false)
    const auth = useAuthContext()
    const { userInfoQuery } = user_info_query.use({ teacherId: auth.userId })
    const [draftDispos, setDraftDispos] = useState<DraftWeekDispos>([])
    const [warning, setWarning] = useState<string>("")
    const dispoByDay = prepareDispoData(draftDispos)
    const [currentId, setCurrentId] = useState<number>(-1)
    const [hasUnstableWeekDispos, setHasUnstableWeekDispos] = useState(false)
    const previousQueryData = usePrevious(userInfoQuery.data)
    const isDraft = isDispoArrayChanged(userInfoQuery.data?.WeekDispos ?? [], draftDispos) || userInfoQuery.data?.hasUnstableWeekDispos !== hasUnstableWeekDispos
    const [closeWarning, setCloseWarning] = useState<boolean>(false)
    const [isInit, setIsInit] = useState(false)
    let firstPressTime: Time

    useEffect(() => {
        if (userInfoQuery.data && previousQueryData?.WeekDispos !== userInfoQuery.data?.WeekDispos) {
            setDraftDispos(userInfoQuery.data.WeekDispos)
        }
        if (userInfoQuery.data && previousQueryData?.hasUnstableWeekDispos !== userInfoQuery.data?.hasUnstableWeekDispos) {
            setHasUnstableWeekDispos(userInfoQuery.data.hasUnstableWeekDispos)
        }
        setIsInit(true)
    }, [previousQueryData, userInfoQuery.data])

    /**
     * handler du premier choix dans le time picker
     * @param time valeur du bouton cliqué
     * @param day jour du bouton cliqué
     * @param id id de la dispo en édition, undefined si nouvelle dispo
     */
    const handleFirstPress = (time: Time, day: Day, id: number | undefined) => {
        firstPressTime = time
        mainStackNavigation.navigate("TimePickerScreen", { day, handleDelete, handleOnPress: handleSecondPress, id, fistPress: firstPressTime, originalDispo: null, sameDayDispos: dispoByDay[day] })
    }

    /**
     * handler du second choix dans le time picker, met à jour le state avec les modifs effectuées
     * @param time valeur du bouton cliqué
     * @param day jour du bouton cliqué
     * @param id id de la dispo en édition, undefined si nouvelle dispo
     */
    const handleSecondPress = async (time: Time, day: Day, id: number | undefined) => {
        mainStackNavigation.goBack()

        setWarning("")

        //test de overlap
        if (!validateDispoInput(getIdFromDay(day), { start: firstPressTime.toString(), end: time.toString() }, draftDispos, id)) {
            setWarning("La disponibilité entrée empiète sur d'autres disponibilités du même jour")
            return
        }

        if (!id) {
            //nouvelle dispo
            const draftCopy = [...draftDispos]
            draftCopy.push({
                id: currentId,
                startTime: firstPressTime.toString(),
                endTime: time.toString(),
                dayId: getIdFromDay(day),
            })
            setDraftDispos(draftCopy)
            setCurrentId(currentId - 1)
        } else {
            //modif d'une dispo
            setDraftDispos([...draftDispos.filter((value) => value.id !== id), { id, startTime: firstPressTime.toString(), endTime: time.toString(), dayId: getIdFromDay(day) }])
        }

        // setIsDraft(true)
    }

    /**
     * supprime une dispo du state
     * @param id id de la dispo à supprimer
     */
    const handleDelete = (id: number) => {
        // console.log(id)
        // console.log(draftDispos)
        mainStackNavigation.goBack()
        setDraftDispos(draftDispos.filter((value) => value.id !== id))
        // setIsDraft(true)
    }

    /**
     * soumet les modifications à la base de données
     */
    const defaultHandleSubmit = useCatchToBoundary(async () => {
        try {
            setSubmitting(true)
            await auth.ensureValidToken()

            await rasmik
                .pushOne(Teacher)
                .pushDef({
                    allow: "update",
                    children: {
                        WeekDispos: {
                            allow: "upsert",
                            collectionMode: "set",
                            strict: true,
                        },
                    },
                })
                .data({ id: auth.userId, WeekDispos: removeNegativeIds(draftDispos), weekDispoLastUpdatedAt: new Date(), hasUnstableWeekDispos })
                .run()

            await userInfoQuery.refetch()

            if (displayAppBar) {
                mainStackNavigation.navigate("MainTabs", { screen: "User" })
            }

            // setIsDraft(false)
        } finally {
            setSubmitting(false)
        }
    })

    const onGoBack = () => {
        if (isDraft) {
            setCloseWarning(true)
        } else {
            mainStackNavigation.goBack()
        }
    }

    const onCloseCancel = () => {
        setCloseWarning(false)
    }

    const onCloseProceed = () => {
        setCloseWarning(false)
        mainStackNavigation.goBack()
    }

    const handleHasUnstableWeekDisposToggle = () => {
        setHasUnstableWeekDispos((prev) => !prev)
    }

    const theme = useTheme()
    const barBg = useColorModeValue(theme.colors.appBar.light, theme.colors.appBar.dark)
    return {
        isInit,
        isSubmitting,
        setSubmitting,
        draftDispos,
        setDraftDispos,
        warning,
        setWarning,
        dispoByDay,
        currentId,
        setCurrentId,
        previousQueryData,
        hasUnstableWeekDispos,
        handleHasUnstableWeekDisposToggle,
        isDraft,
        closeWarning,
        setCloseWarning,
        auth,
        handleFirstPress,
        handleSecondPress,
        defaultHandleSubmit,
        handleDelete,
        onGoBack,
        onCloseCancel,
        onCloseProceed,
        theme,
        barBg,
    }
}
