import type { YearDispo } from "@core/services/rasmik-client"
import { capitalize, isNil, isNumber } from "lodash"
import { DateTime, Duration } from "luxon"

import type { DayDate } from "./DayDate"
import { conditional } from "./textUtils"

// export { default as capitalize } from 'capitalize'
export function dayName(dayId: number, type: "full" | "medium" | "short" = "full") {
    let days
    if (type === "full")
        days = {
            1: "lundi",
            2: "mardi",
            3: "mercredi",
            4: "jeudi",
            5: "vendredi",
            6: "samedi",
            7: "dimanche",
        }
    else if (type === "medium")
        days = {
            1: "lun",
            2: "mar",
            3: "mer",
            4: "jeu",
            5: "ven",
            6: "sam",
            7: "dim",
        }
    else if (type === "short")
        days = {
            1: "Lu",
            2: "Ma",
            3: "Me",
            4: "Je",
            5: "Ve",
            6: "Sa",
            7: "Di",
        }

    //@ts-ignore
    return days[dayId] as string
}

export const percentFormat = (number: number, decimalPlaces = 0) => {
    if (typeof number !== "number" || isNaN(number)) return ""
    return (number * 100).toFixed(decimalPlaces).toString() + "%"
}

export function formatString(number: number | string, mask: string) {
    const s = "" + number
    let r = ""
    for (let im = 0, is = 0; im < mask.length && is < s.length; im++) {
        r += mask.charAt(im) === "X" ? s.charAt(is++) : mask.charAt(im)
    }
    return r
}

export function formatBoolean(bool: boolean, strTrue = "oui", strFalse = "non", strNull = "inconnu") {
    //alert(bool)
    if (bool === false) return strFalse
    if (bool === true) return strTrue
    if (bool === null || bool === undefined) return strNull
}

//   //format phone number for display (removes +33 and add spaces for french number only)
//   export function formatPhoneNumber(phoneNumber){
//     if (!phoneNumber) return ''
//     const cleaned = phoneNumber.replace(/ /g,"")
//     if(cleaned.substr(0,3) === "+33"){
//       return formatString(cleaned.replace("+33","0"),"XX XX XX XX XX")
//     }else{
//       return phoneNumber
//     }
//   }

//   //convert phone number to international format without spaces
// export function standardizePhoneNumber(phoneNumber){
//   if (!phoneNumber) return null
//   const cleaned = phoneNumber.replace(/ /g,"")
//   if(phoneNumber.substr(0,1)==="+")return cleaned;
//   else return "+33" + cleaned.substr(1,9)
//   }

export function customRound$(value: number | null, precision: string, mode: "round" | "floor" | "ceil" = "round") {
    if (!isNumber(value)) return value
    const tofixedValue = precision.split(".")[1]?.length
    const precisionNb = parseFloat(precision)
    const quotient = Math.floor(value / precisionNb)

    if (mode === "floor") {
        return (quotient * precisionNb).toFixed(tofixedValue)
    } else if (mode === "ceil") {
        return ((quotient + 1) * precisionNb).toFixed(tofixedValue)
    } else if (mode === "round") {
        if (Math.abs(value - quotient * precisionNb) <= Math.abs(value - (quotient + 1) * precisionNb)) {
            return (quotient * precisionNb).toFixed(tofixedValue)
        } else {
            return ((quotient + 1) * precisionNb).toFixed(tofixedValue)
        }
    }
}

export function customRound(value: number | null, precision: string, mode: "round" | "floor" | "ceil" = "round") {
    return parseFloat(customRound$(value, precision, mode)!)
}

export function joinEt(array: string[]): string {
    const lastItem = array.pop()
    const finalArr = []

    array.length && finalArr.push(array.join(", "))
    lastItem && finalArr.push(lastItem)

    return finalArr.join(" et ")
}

export function humanizeDuration(ms: number, decimals: number = 0) {
    if (decimals < 0 || decimals > 3) throw new Error("Bad value '" + decimals + "' for 'decimals'. Must be between 0 and 3")

    const duration = Duration.fromMillis(ms)
    let str = ""
    try {
        //plus de 1h
        if (ms >= 3600 * 1000) {
            str = duration.toFormat("h'h' mm'min' ss.SSS's'")
        }

        //entre 1min et 1h_
        else if (ms >= 60 * 1000) {
            str = duration.toFormat("m'min' ss.SSS's'")
        }

        //de 1s à 1min_
        else if (ms >= 1 * 1000) {
            str = duration.toFormat("s.SSS's'")
        }

        //entre 0s et 1s
        else {
            str = duration.toFormat("s.SSS's'")
        }

        if (decimals === 0) {
            str = str.slice(0, -5) + "s"
        } else {
            str = str.slice(0, decimals - 3 - 1) + "s"
        }
    } catch (err) {
        console.debug(err)
    }

    return str
}

export function formatDurationMin(minutes: number) {
    if (isNaN(minutes) || isNil(minutes) || typeof minutes !== "number") return ""

    const isNegative = minutes < 0
    const absMinutes = Math.abs(minutes)
    const mins = absMinutes % 60
    const hours = (absMinutes - mins) / 60

    return `${conditional("-", isNegative)}${`${hours.toString(10)}h${mins.toString(10).padStart(2, "0")}`.replace("h00", "h").replace(/^0h(\d\d)$/, "$1min")}`
}

export function age(birthDate: DayDate) {
    if (isNil(birthDate)) return ""
    const years = Math.floor(-birthDate.toLuxonDateTime().diffNow("years").years)
    return years + (years > 0 ? " ans" : " an")
}

export function formatPhoneNumber(phoneNumber: string) {
    if (!phoneNumber) return phoneNumber
    const cleaned = phoneNumber.replace(/ /g, "")
    if (cleaned.substr(0, 3) === "+33") {
        return formatString(cleaned.replace("+33", "0"), "XX XX XX XX XX")
    } else {
        return phoneNumber
    }
}

export function verifyPhoneNumber(phoneNumber: string): [isOk: boolean, msg?: string] {
    const cleaned = phoneNumber.replace(/ /g, "")
    if (!cleaned) return [false, "Requis"]

    const regex = /^\s*\+?\s*([0-9][\s-]*){9,}$/
    if (!regex.test(cleaned)) return [false, "Incorrect"]

    const standardized = standardizePhoneNumber(phoneNumber)

    if (!standardized) return [false, "Requis"]
    if (!cleaned.includes("+") && cleaned.length !== 10) return [false, "Incorrect"] //num français pas a 10 digit
    if (standardized && standardized.length > 1) return [true]
    else return [false, "Incorrect"]
}

//convert phone number to international format without spaces
export function standardizePhoneNumber(phoneNumber: string) {
    const cleaned = phoneNumber.replace(/ /g, "")
    if (phoneNumber.substr(0, 1) === "+") return cleaned
    else return "+33" + cleaned.substr(1, 9)
}

export function trim(string: string, tokenToRemove: string, side: "left" | "right" | "both" = "both") {
    tokenToRemove = tokenToRemove.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&")

    if (side === "both") {
        return string.replace(new RegExp("^[" + tokenToRemove + "]+|[" + tokenToRemove + "]+$", "g"), "")
    }

    if (side === "left") {
        return string.replace(new RegExp("^[" + tokenToRemove + "]+", "g"), "")
    }

    if (side === "right") {
        return string.replace(new RegExp("[" + tokenToRemove + "]+$", "g"), "")
    }
}

export function frequencyToText(frequency: number) {
    if (isNil(frequency)) return ""
    const textFrequency = {
        0: "Ne sais pas",
        1: "1 fois par mois",
        2: "1 fois toutes les 2 semaines",
        4: "1 fois par semaine",
        8: "2 fois par semaine",
        12: "3 fois par semaine",
        16: "4 fois par semaine",
        20: "5 fois par semaine",
        24: "6 fois par semaine",
        28: "tous les jours",
    }[frequency]

    return textFrequency
}

export function formatEuro(value: number | string, trim: boolean = true) {
    if (typeof value === "string") value = parseFloat(value)
    if (isNil(value) || isNaN(value)) return ""
    else return trim ? `${value.toFixed(2).replace(/\.00$/, "")}€` : `${value.toFixed(2)}€`
}

/**
 * Capitales à chaque mot si au moins deux lettres (ex: Hubert Bonisseur de la Bath) ou bien si c'est la 1ere lettre
 */
export function capitalizeName<T extends string | null | undefined>(name: T): T {
    if (!name) return name

    const parts = name.toLowerCase().match(/\p{L}+[^\p{L}]?/gu)! // "\p{L}" any letter category in unicode (\w does not work with accented characters)"
    return parts.map((part, index) => (part.trim().length >= 3 || index === 0 ? capitalize(part) : part)).join("") as T
}

export function displayDateRange(yearDispo: Pick<YearDispo, "startOn" | "endOn">) {
    return capitalize(
        `${yearDispo.endOn ? "" : "à partir du "}${yearDispo.startOn ? DateTime.fromISO(yearDispo.startOn).toFormat("'du' dd MMM yyyy") : ""}${yearDispo.startOn && yearDispo.endOn ? " au " : " jusqu'au "}${
            yearDispo.endOn ? DateTime.fromISO(yearDispo.endOn).toFormat("dd MMM yyyy") : ""
        }`
    )
}

export function displayNextYearDispoAt() {
    const dt = DateTime.now()

    if (dt.month >= 8) {
        return `${dt.year + 1}/${dt.year + 2}`
    } else {
        return `${dt.year}/${dt.year + 1}`
    }
}
