import { useAuthContext } from "@core/context/auth"
import { type DialogLogic, useCatchToBoundary, useDialog, useSpinner } from "@core/hooks"
import { current_proposition_lessons_query, current_real_lessons_query, match_infos_list_query, user_info_query } from "@core/queries"
import { openapi } from "@core/services/openapi"
import { DateTime } from "luxon"
import { Button, HStack, Modal, Spacer, Text } from "native-base"
import React from "react"

/**
 * Actions pour acceptation d'un créneau proposé a un prof déjà attribué
 */
export function DraftNotAcceptedActions({ lessonId, matchId }: { matchId: number; lessonId: number }) {
    const auth = useAuthContext()
    const { currentRealLessonsQuery } = current_real_lessons_query.use({ teacherId: auth.userId }, { staleTime: 5000 })

    const lesson = currentRealLessonsQuery.helpers.findLessonById(lessonId)
    const { userInfoQuery } = user_info_query.use({ teacherId: auth.userId })

    const acceptDialog = useDialog()
    const refuseDialog = useDialog()

    return (
        <HStack alignItems="center" justifyContent="flex-end">
            {acceptDialog.isOpen && <AcceptModal logic={acceptDialog} lessonId={lessonId} matchId={matchId} />}
            {refuseDialog.isOpen && <RefuseModal logic={refuseDialog} lessonId={lessonId} matchId={matchId} />}

            <Button variant="subtle2" colorScheme="brown" minWidth="100px" onPress={() => refuseDialog.open()}>
                Refuser
            </Button>
            <Spacer />
            <Button variant="solid" colorScheme="pedagome" minWidth="100px" onPress={() => acceptDialog.open()}>
                Accepter
            </Button>
        </HStack>
    )
}

export function AcceptModal({ logic, lessonId, matchId }: { logic: DialogLogic; matchId: number; lessonId: number }) {
    const auth = useAuthContext()

    const { currentRealLessonsQuery } = current_real_lessons_query.use({ teacherId: auth.userId }, { staleTime: 5000 })
    const { currentPropositionLessonsQuery } = current_proposition_lessons_query.use({ teacherId: auth.userId }, { staleTime: 5000 })

    const { matchInfosListQuery } = match_infos_list_query.use({ teacherId: auth.userId })
    const match = matchInfosListQuery.helpers.findById(matchId)

    const lesson = currentPropositionLessonsQuery.helpers?.findLessonById?.(lessonId)

    const spinner = useSpinner()

    const handleAccept = useCatchToBoundary(async () => {
        spinner.start()

        try {
            await auth.ensureValidToken()
            await openapi.teachersApp.acceptLessonProposition(lessonId)

            //refetch base query
            await Promise.allSettled([currentPropositionLessonsQuery.refetch(), currentRealLessonsQuery.refetch()])

            logic.hide()
        } finally {
            spinner.stop()
        }
    })

    return (
        <Modal isOpen>
            <Modal.Content>
                {/*   <Modal.CloseButton /> */}
                <Modal.Header>Acceptation d'un cours</Modal.Header>
                <Modal.Body>
                    <Text mt="4px" fontSize="xs" _light={{ color: "primary.500" }} _dark={{ color: "primary.400" }} fontWeight="500">
                        {match?.label} {match?.Program?.Learner?.firstName ? "pour " + match?.Program?.Learner?.firstName : ""}
                    </Text>

                    <Text>
                        {lesson?.startedAtPlanned && DateTime.fromJSDate(lesson.startedAtPlanned).toFormat("EEEE d MMMM 'de' H'h'mm").toLowerCase().replace("h00", "h")} à{" "}
                        {lesson?.startedAtPlanned &&
                            DateTime.fromJSDate(lesson.startedAtPlanned)
                                .plus({ minutes: lesson.durationMinPlanned ?? 0 })
                                .toFormat("H'h'mm")
                                .replace("h00", "h")}
                    </Text>
                </Modal.Body>
                <Modal.Footer>
                    <Button.Group space={2}>
                        <Button onPress={() => logic.hide()} variant="subtle2" colorScheme="trueGray">
                            Retour
                        </Button>
                        <Button onPress={handleAccept} isLoading={spinner.loading} variant="solid" colorScheme="pedagome">
                            Accepter le cours
                        </Button>
                    </Button.Group>
                </Modal.Footer>
            </Modal.Content>
        </Modal>
    )
}

function RefuseModal({ logic, lessonId, matchId }: { logic: DialogLogic; matchId: number; lessonId: number }) {
    const auth = useAuthContext()
    const { currentPropositionLessonsQuery } = current_proposition_lessons_query.use({ teacherId: auth.userId }, { staleTime: 5000 })

    const { matchInfosListQuery } = match_infos_list_query.use({ teacherId: auth.userId })
    const match = matchInfosListQuery.helpers.findById(matchId)

    const lesson = currentPropositionLessonsQuery.helpers?.findLessonById?.(lessonId)

    const spinner = useSpinner()

    const handleRefuse = useCatchToBoundary(async () => {
        spinner.start()

        try {
            await auth.ensureValidToken()
            await openapi.teachersApp.refuseLessonProposition(lessonId, { abortComment: "" })

            //refetch base query
            await currentPropositionLessonsQuery.refetch()

            logic.hide()
        } finally {
            spinner.stop()
        }
    })

    return (
        <Modal isOpen>
            <Modal.Content>
                {/*   <Modal.CloseButton /> */}
                <Modal.Header>Refus d'une proposition de cours</Modal.Header>
                <Modal.Body>
                    <Text mt="4px" fontSize="xs" _light={{ color: "primary.500" }} _dark={{ color: "primary.400" }} fontWeight="500">
                        {match?.label} {match?.Program?.Learner?.firstName ? "pour " + match?.Program?.Learner?.firstName : ""}
                    </Text>

                    <Text>
                        {lesson?.startedAtPlanned && DateTime.fromJSDate(lesson.startedAtPlanned).toFormat("EEEE d MMMM 'de' H'h'mm").toLowerCase().replace("h00", "h")} à{" "}
                        {lesson?.startedAtPlanned &&
                            DateTime.fromJSDate(lesson.startedAtPlanned)
                                .plus({ minutes: lesson.durationMinPlanned ?? 0 })
                                .toFormat("H'h'mm")
                                .replace("h00", "h")}
                    </Text>
                </Modal.Body>
                <Modal.Footer>
                    <Button.Group space={2}>
                        <Button onPress={() => logic.hide()} variant="subtle2" colorScheme="trueGray">
                            Retour
                        </Button>
                        <Button onPress={handleRefuse} isLoading={spinner.loading} variant="solid" colorScheme="brown">
                            Refuser le cours
                        </Button>
                    </Button.Group>
                </Modal.Footer>
            </Modal.Content>
        </Modal>
    )
}
