import { ContentContainerColumn, ContentContainerRow } from "@core/misc-components/ContentContainer"
import { AppBar } from "@core/molecules/AppBar"
import { FontAwesome5 } from "@expo/vector-icons"
import type { StackScreenProps } from "@react-navigation/stack"
import { Badge, Button, type ColorMode, Heading, HStack, Icon, Spacer, useColorMode } from "native-base"
import { ScrollView } from "react-native"

import type { MainStackParamsList } from "../../MainStack"
import type { Day, DraftWeekDispo } from "../EditWeekDispoForm"
import { formatISOTime } from "./utils"

const colorScheme = "blue"
const colors = {
    text: {
        lm: `#535353`,
        dm: `${colorScheme}.100`,
    },
    textSelected: {
        lm: `${colorScheme}.900`,
        dm: `${colorScheme}.50`,
    },
    normal: {
        lm: `#00000008`,
        dm: `#313131`,
    },
    hover: {
        lm: `${colorScheme}.400`,
        dm: `${colorScheme}.700`,
    },
    inRange: {
        lm: `${colorScheme}.200`,
        dm: `${colorScheme}.800`,
    },
    selected: {
        lm: `${colorScheme}.400`,
        dm: `${colorScheme}.700`,
    },
}

/**
 * Composant permettant de choisir une étendue de temps, voir le typage des props dans MainStack.tsx
 */
export function TimePickerScreen({ navigation, route }: StackScreenProps<MainStackParamsList, "TimePickerScreen">) {
    const eligibleTimes = getEligibleTimes()
    return (
        <>
            <AppBar onBack={navigation.goBack}>
                <ContentContainerRow>
                    <Heading size="md">{`${route.params.day}`}</Heading>
                    <Spacer />
                    {route.params.id !== undefined ? (
                        <Button leftIcon={<Icon as={FontAwesome5} name="trash-alt" size="xs" />} onPress={() => route.params.handleDelete(route.params.id!)} marginRight={3} variant="outline" colorScheme="red">
                            Supprimer
                        </Button>
                    ) : (
                        <Badge colorScheme="blue" marginRight={2}>
                            Nouvelle dispo
                        </Badge>
                    )}
                </ContentContainerRow>
            </AppBar>
            <ScrollView style={{ width: "100%", height: 0 }} contentContainerStyle={{ flexGrow: 1, width: "100%" }}>
                <ContentContainerColumn space={2} marginTop={1}>
                    {eligibleTimes.map((value) => {
                        return (
                            <HStack mt="2" key={value[0].toString()} space={2} justifyContent="space-between">
                                {value[0] && (
                                    <TimeButton
                                        sameDayDispo={route.params.sameDayDispos}
                                        day={route.params.day}
                                        firstPress={route.params.fistPress}
                                        id={route.params.id}
                                        handleOnPress={route.params.handleOnPress}
                                        originalDispo={route.params.originalDispo}
                                        value={value[0]}
                                    />
                                )}
                                {value[1] && (
                                    <TimeButton
                                        sameDayDispo={route.params.sameDayDispos}
                                        day={route.params.day}
                                        firstPress={route.params.fistPress}
                                        id={route.params.id}
                                        handleOnPress={route.params.handleOnPress}
                                        originalDispo={route.params.originalDispo}
                                        value={value[1]}
                                    />
                                )}
                                {value[2] && (
                                    <TimeButton
                                        sameDayDispo={route.params.sameDayDispos}
                                        day={route.params.day}
                                        firstPress={route.params.fistPress}
                                        id={route.params.id}
                                        handleOnPress={route.params.handleOnPress}
                                        originalDispo={route.params.originalDispo}
                                        value={value[2]}
                                    />
                                )}
                                {value[3] && (
                                    <TimeButton
                                        sameDayDispo={route.params.sameDayDispos}
                                        day={route.params.day}
                                        firstPress={route.params.fistPress}
                                        id={route.params.id}
                                        handleOnPress={route.params.handleOnPress}
                                        originalDispo={route.params.originalDispo}
                                        value={value[3]}
                                    />
                                )}
                            </HStack>
                        )
                    })}
                </ContentContainerColumn>
            </ScrollView>
        </>
    )
}

function TimeButton(props: {
    value: Time
    day: Day
    id: number | undefined
    handleOnPress: (time: Time, day: Day, id: number | undefined) => void
    firstPress: Time | null
    originalDispo: { start: string; end: string } | null
    sameDayDispo: DraftWeekDispo[]
}) {
    const { colorMode } = useColorMode()
    const variant = props.firstPress?.equalsTo(props.value) ? "solid" : "subtle"
    const textStyle = { color: isInTimeRange(props.originalDispo, props.value) ? getThemedColor("textSelected", colorMode) : getThemedColor("text", colorMode) }
    const isInSameDayTimeRangeVar = isInSameDayTimeRange(props.value, props.sameDayDispo, props.id)
    const isOverlappingVar = isOverlapping(props.value, props.sameDayDispo, props.id, props.firstPress)
    const isDisabled = props.value.compareTo(props.firstPress) < 0 || isOverlappingVar
    const hoverStyle = colorMode === "light" ? colors.hover.lm : colors.hover.dm
    const bgColor = getButtonColorVariant(props.originalDispo, props.value, props.firstPress, colorMode, isInSameDayTimeRangeVar)

    return (
        <Button
            isDisabled={isDisabled}
            _hover={{ bgColor: hoverStyle }}
            onPress={() => props.handleOnPress(props.value, props.day, props.id)}
            variant={variant}
            bgColor={bgColor}
            _text={textStyle}
            width="20%"
            height="32px"
            flexGrow={1}
        >
            {props.value.toStringISO()}
        </Button>
    )
}

/**
 * ttest si une valeur donnée est dans une dispo du même jour, utilisée pour disable les boutons
 * @param value valeur à tester
 * @param sameDayDispos autres dispos du même jour
 * @param id id à ignorer (dans le cas d'une modification par exemple)
 * @returns true si la valeur testée est déjà dans une autre dispo du même jour
 */
function isInSameDayTimeRange(value: Time, sameDayDispos: DraftWeekDispo[], id: number | undefined): boolean {
    return sameDayDispos.find((arrayValue) => isInTimeRange({ start: arrayValue.startTime, end: arrayValue.endTime }, value) && arrayValue.id !== id) !== undefined
}

/**
 * determine si une horaire est en overlap et doit être disabled
 * @param value valeur à tester
 * @param sameDayDispos autres dispos du même jour
 * @param id id à ignorer (dans le cas d'une modification par exemple)
 * @param firstPress première horaire saisie dans le cas du deuxième écran

 */
function isOverlapping(value: Time, sameDayDispos: DraftWeekDispo[], id: number | undefined, firstPress: Time | null): boolean {
    if (isInSameDayTimeRange(value, sameDayDispos, id)) return true

    //si on est pas dans le deuxième écran on quitte
    if (!firstPress) return false

    //test si valeur > firstPress et valeur < premierJour de dispo déjà prise après valeur
    if (value.compareTo(firstPress) > 0 && value.compareTo(getFirstSameDayDispoFromDay(firstPress, sameDayDispos, id)) > 0) return true

    return false
}

/**
 * retourn la première dispo déjà occupée après une certaine horaire
 * @param value valeur à partir de laquelle on teste
 * @param sameDayDispos autres dispos du même jour
 * @param idToIgnore id à ignorer (dans le cas d'une modification par exemple)
 */
function getFirstSameDayDispoFromDay(value: Time, sameDayDispos: DraftWeekDispo[], idToIgnore: number | undefined): Time | null {
    const currentTime = value.copy()
    currentTime.add15Minutes()

    while (currentTime.compareTo(new Time(22, 15, 0)) < 0) {
        if (isInSameDayTimeRange(currentTime, sameDayDispos, idToIgnore)) {
            return currentTime
        }
        currentTime.add15Minutes()
    }

    return new Time(22, 15, 0)
}

/**
 * retourne la couleur souhaitée selon le colormode (light ou dark)
 * @param kind type de la couleur voulue
 * @param colorMode light ou dark
 * @returns la couleur selon le mode
 */
export function getThemedColor(kind: keyof typeof colors, colorMode: ColorMode) {
    if (colorMode === "dark") return colors[kind].dm
    else return colors[kind].lm
}

/**
 * renvoie la couleur d'un bouton selon son contexte
 * @param originalDispo dispo originale du de la dispo qu'on edit actuellement
 * @param value valeur du bouton
 * @param firstPress valeur saisie au premier ecran
 * @param colorMode colorMode actuel
 * @param sameDayDispo autres dispos du même jour
 */
function getButtonColorVariant(originalDispo: { start: string; end: string } | null, value: Time, firstPress: Time | null, colorMode: ColorMode, sameDayDispo: boolean): string {
    let colorModeKey: "lm" | "dm" = "lm"
    if (colorMode === "dark") colorModeKey = "dm"

    if (sameDayDispo) return colors.inRange[colorModeKey]
    if (value.equalsTo(firstPress)) return colors.selected[colorModeKey]
    if (value.toString() === originalDispo?.start || value.toString() === originalDispo?.end) return colors.selected[colorModeKey]
    if (isInTimeRange(originalDispo, value)) return colors.inRange[colorModeKey]
    return colors.normal[colorModeKey]
}

/**
 * retourne toutes les heures à afficher dans les boutons, de 8h à 22h
 * @returns un tableau à deux dimension de toute les boutons à afficher
 */
function getEligibleTimes(): Time[][] {
    const res: Time[][] = []
    const currentTime = new Time(8, 0, 0)
    const endTime = new Time(22, 0, 0)

    do {
        const tempRes: Time[] = []
        for (let index = 0; index < 4; index++) {
            tempRes.push(currentTime.copy())
            currentTime.add15Minutes()
        }
        res.push(tempRes)
    } while (!currentTime.equalsTo(endTime))
    res.push([new Time(22, 0, 0)])
    return res
}

/**
 * teste si une valeur est dans un étendue de temps
 * @param timeRange étendue de temps dans a laquelle on teste
 * @param value valeur à tester
 */
function isInTimeRange(timeRange: { start: string; end: string } | null, value: Time): boolean {
    if (!timeRange) return false
    const valueString = value.toString()

    return timeRange.start <= valueString && timeRange.end >= valueString
}

export class Time {
    seconds: number
    minutes: number
    hours: number

    constructor(hours: number, minutes: number, seconds: number) {
        if (hours < 24) {
            this.hours = hours
        } else {
            throw new Error("Invalid Time : hours must be below 24")
        }
        if (minutes < 60) {
            this.minutes = minutes
        } else {
            throw new Error("Invalid Time : minutes must be below 60")
        }
        if (seconds < 60) {
            this.seconds = seconds
        } else {
            throw new Error("Invalid Time : seconds must be below 60")
        }
    }

    toString(): string {
        let hoursString = ""
        let minutesString = ""
        let secondsStrings = ""

        if (this.hours < 10) {
            hoursString = `0${this.hours}`
        } else {
            hoursString = this.hours.toString()
        }

        if (this.minutes < 10) {
            minutesString = `0${this.minutes}`
        } else {
            minutesString = this.minutes.toString()
        }

        if (this.seconds < 10) {
            secondsStrings = `0${this.seconds}`
        } else {
            secondsStrings = this.seconds.toString()
        }

        return `${hoursString}:${minutesString}:${secondsStrings}`
    }

    toStringISO(): string {
        return formatISOTime(this.toString())
    }

    add15Minutes(): void {
        if (this.minutes + 15 < 60) {
            this.minutes += 15
            return
        }
        this.minutes = 0
        if (this.hours + 1 < 24) {
            this.hours += 1
            return
        }
        this.hours = 0
    }

    equalsTo(time: Time | null): boolean {
        if (!time) return false
        return this.hours === time.hours && this.minutes === time.minutes && this.seconds === time.seconds
    }

    compareTo(time: Time | null): number {
        if (!time) return 1
        if (this.hours - time.hours !== 0) return this.hours - time.hours
        if (this.minutes - time.minutes !== 0) return this.minutes - time.minutes
        if (this.seconds - time.seconds !== 0) return this.seconds - time.seconds
        return 0
    }

    copy(): Time {
        return new Time(this.hours, this.minutes, this.seconds)
    }
}
