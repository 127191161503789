import { ScrollViewGrow } from "@core/atoms"
import { Card } from "@core/atoms/Card"
import { useAuthContext } from "@core/context/auth"
import { useCatchToBoundary, useSpinner } from "@core/hooks"
import { ContentContainer } from "@core/misc-components/ContentContainer"
import { ToggleDarkMode } from "@core/misc-components/ToggleDarkMode"
import { LoadingScreen } from "@core/organism/LoadingScreen"
import { user_info_query } from "@core/queries"
import { displayDateRange, displayNextYearDispoAt, formatPhoneNumber, isDispoNextYear } from "@core/utils"
import { FontAwesome5 } from "@expo/vector-icons"
import type { NavigationProp } from "@react-navigation/native"
import type { StackScreenProps } from "@react-navigation/stack"
import { Button, ChevronRightIcon, Heading, HStack, Icon, Text, useBreakpointValue, VStack } from "native-base"
import React from "react"
import { useErrorBoundary } from "react-error-boundary"
import { RefreshControl } from "react-native"

import type { MainStackParamsList, MainTabsParamList } from "../MainStack"
import { formatISOTime, getDayFromNumber, groupWeekDisposByDay } from "./WeekDispoScreenComponentAndUtils/utils"

export function UserScreen({ navigation }: StackScreenProps<MainTabsParamList, "User">) {
    const auth = useAuthContext()

    const { userInfoQuery } = user_info_query.use({ teacherId: auth.userId })
    const tabBarPosition: "top" | "bottom" = useBreakpointValue({
        base: "bottom",
        md: "top",
    })

    const { showBoundary } = useErrorBoundary()
    const signOut = useCatchToBoundary(async () => {
        auth.eraseToken()
    }, [auth])

    const scrollRefreshSpinner = useSpinner()
    const onRefresh = useCatchToBoundary(async () => {
        console.debug("UserScreen scroll refresh")
        try {
            await auth.ensureValidToken()
            scrollRefreshSpinner.start()
            await userInfoQuery.refetch()
        } finally {
            scrollRefreshSpinner.stop()
        }
    }, [auth, scrollRefreshSpinner, userInfoQuery])

    const teacher = userInfoQuery.data
    if (!teacher || userInfoQuery.isLoading) return <LoadingScreen />
    return (
        <VStack safeAreaBottom width="100%" h="100%">
            <ScrollViewGrow refreshControl={<RefreshControl refreshing={scrollRefreshSpinner.loading} onRefresh={onRefresh} />}>
                <VStack
                    nativeID="profile-appbar"
                    safeAreaTop
                    pb="3"
                    pt={tabBarPosition === "top" ? "5" : "0"}
                    _light={{ backgroundColor: "appBar.light" }}
                    _dark={{ backgroundColor: "appBar.dark" }}
                    shadow="1" /*  height="110px" */
                >
                    <VStack alignItems="center">
                        <Icon as={FontAwesome5} name="user-circle" size={30} />
                        <Heading fontSize="2xl" mb="5">
                            {teacher.firstName} {teacher.lastName}
                        </Heading>
                        {/* <Text fontSize="sm">{teacher.email}</Text>
                        <Text fontSize="sm">{(teacher.MainPhoneNumber?.formatedNumber as string) || ""}</Text> */}
                    </VStack>
                </VStack>

                <ContentContainer space="15px" paddingY="15px" alignItems="center">
                    {/* <Card>
        <HStack justifyContent="space-between" alignItems="center">
          <Text>Email et mot de passe</Text>
          <ChevronRightIcon/>
          </HStack>
      </Card>

      <Card>

      <Card>
        <HStack justifyContent="space-between" alignItems="center">
          <Text>Disponibilités</Text>
          <ChevronRightIcon/>
          </HStack>
      </Card>

      <Card>
        <HStack justifyContent="space-between" alignItems="center">
          <Text>Coordonnées bancaires</Text>
          <ChevronRightIcon/>
          </HStack>
      </Card> */}

                    <Card onPress={() => navigation.getParent<NavigationProp<MainStackParamsList, "History">>().navigate("Payslips")}>
                        <HStack justifyContent="space-between" alignItems="center">
                            <Text>Bulletins de paie</Text>
                            <ChevronRightIcon />
                        </HStack>
                    </Card>

                    <Card onPress={() => navigation.getParent<NavigationProp<MainStackParamsList, "History">>().navigate("EditInfoForm")}>
                        <HStack justifyContent="space-between" alignItems="center">
                            <VStack>
                                <Text>Mes coordonnées</Text>
                                <Text _dark={{ color: "darkTextMild" }} _light={{ color: "lightTextMild" }}>
                                    {userInfoQuery.data.email}
                                </Text>
                                <Text _dark={{ color: "darkTextMild" }} _light={{ color: "lightTextMild" }}>
                                    {formatPhoneNumber(userInfoQuery.data.MainPhoneNumber?.number || "")}
                                </Text>
                                <Text _dark={{ color: "darkTextMild" }} _light={{ color: "lightTextMild" }}>
                                    {userInfoQuery.data.Addresses.map((addr) => addr.fullStreet + " à " + addr.cityName)}
                                </Text>{" "}
                                <Text _dark={{ color: "darkTextMild" }} _light={{ color: "lightTextMild" }}>
                                    ...
                                </Text>
                            </VStack>
                            <ChevronRightIcon />
                        </HStack>
                    </Card>

                    <Card onPress={() => navigation.getParent<NavigationProp<MainStackParamsList, "History">>().navigate("EditScolarityForm")}>
                        <HStack justifyContent="space-between" alignItems="center">
                            <VStack>
                                <Text>Mes études</Text>
                                <Text _dark={{ color: "darkTextMild" }} _light={{ color: "lightTextMild" }}>
                                    {userInfoQuery.data.StudyLevel?.label}
                                </Text>
                                <Text _dark={{ color: "darkTextMild" }} _light={{ color: "lightTextMild" }}>
                                    {userInfoQuery.data.ScolarityMain?.label} {userInfoQuery.data.ScolarityComplement?.label}
                                </Text>
                            </VStack>
                            <ChevronRightIcon />
                        </HStack>
                    </Card>

                    <Card onPress={() => navigation.getParent<NavigationProp<MainStackParamsList, "History">>().navigate("EditWeekDispoForm")}>
                        <HStack justifyContent="space-between" alignItems="center">
                            <VStack>
                                <Text>Mes disponibilités hebdo</Text>
                                {groupWeekDisposByDay(userInfoQuery.data.WeekDispos).map((dayItem) => (
                                    <Text key={dayItem.dayId} _dark={{ color: "darkTextMild" }} _light={{ color: "lightTextMild" }}>
                                        {getDayFromNumber(dayItem.dayId)} {dayItem.dayDispos.map((disp) => formatISOTime(disp.startTime) + "-" + formatISOTime(disp.endTime)).join(", ")}
                                    </Text>
                                ))}
                                {userInfoQuery.data.WeekDispos?.length === 0 && (
                                    <Text _dark={{ color: "darkTextMild" }} _light={{ color: "lightTextMild" }}>
                                        ⚠ Aucune disponibilité hebdo renseignée !
                                    </Text>
                                )}
                            </VStack>
                            <ChevronRightIcon />
                        </HStack>
                    </Card>

                    <Card onPress={() => navigation.getParent<NavigationProp<MainStackParamsList, "History">>().navigate("EditYearDispoForm")}>
                        <HStack justifyContent="space-between" alignItems="center">
                            <VStack>
                                <Text>Mes disponibilités annuelles</Text>
                                {userInfoQuery.helpers.currentYearDispos().map((yearDispo) => (
                                    <Text key={yearDispo.id} _dark={{ color: "darkTextMild" }} _light={{ color: "lightTextMild" }}>
                                        {displayDateRange(yearDispo)}
                                    </Text>
                                ))}
                                {userInfoQuery.helpers.currentYearDispos().length === 0 ? (
                                    <Text _dark={{ color: "darkTextMild" }} _light={{ color: "lightTextMild" }}>
                                        ⚠ Aucune disponibilité renseignée pour cette année
                                    </Text>
                                ) : (
                                    <Text _dark={{ color: "darkTextMild" }} _light={{ color: "lightTextMild" }}>
                                        {isDispoNextYear(userInfoQuery.data) ? "Et aussi en" : "Mais pas en"} {displayNextYearDispoAt()}
                                    </Text>
                                )}
                            </VStack>
                            <ChevronRightIcon />
                        </HStack>
                    </Card>

                    <Card onPress={() => navigation.getParent<NavigationProp<MainStackParamsList, "History">>().navigate("Tuto")}>
                        <HStack justifyContent="space-between" alignItems="center">
                            <Text>Mode d'emploi</Text>
                            <ChevronRightIcon />
                        </HStack>
                    </Card>

                    <Card>
                        <HStack justifyContent="space-between" alignItems="center">
                            <Text>Thème</Text>
                            <ToggleDarkMode />
                        </HStack>
                    </Card>

                    <Button colorScheme="pedagome" _text={{ color: "lightText" }} onPress={signOut} maxWidth="md">
                        Déconnexion
                    </Button>

                    {teacher.email?.endsWith("@pedagome.fr") && (
                        <Button
                            onPress={() => {
                                const err = new Error("test")
                                showBoundary(err)
                            }}
                        >
                            Test
                        </Button>
                    )}
                </ContentContainer>
            </ScrollViewGrow>
        </VStack>
    )
}
