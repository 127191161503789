import { AuthContextProvider } from "@core/context/auth"
import { ReloginDialog } from "@core/context/auth/ReloginDialog"
import { ErrorFallback } from "@core/misc-components/ErrorFallback"
import { colorModeManager } from "@core/utils/colorModeManager"
import { WorkSans_300Light, WorkSans_400Regular, WorkSans_500Medium, WorkSans_600SemiBold } from "@expo-google-fonts/work-sans"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { StoreProvider, useStoreRehydrated } from "easy-peasy"
import * as Font from "expo-font"
import * as SplashScreen from "expo-splash-screen"
import "intl"
import "intl/locale-data/jsonp/fr"
import { Settings } from "luxon"
import { NativeBaseProvider } from "native-base"
import React, { type ReactNode, useCallback, useEffect, useState } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { LogBox, Platform } from "react-native"

import { Navigator } from "./core/config/Navigator"
import { theme } from "./core/config/theme"
import { MsgBoxProvider } from "./core/organism/msgbox"
import { store } from "./core/store"
import { TestQuery } from "./new-stack/TestQuery"

// import { BadConnexionFallback, EnforceConnexion } from './components/BadConnexion';

Settings.defaultLocale = "fr"

LogBox.ignoreLogs([
    "NativeBase:",
    "Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.",
    "[react-native-gesture-handler] Seems like you're using an old API",
    /Warning: Can't perform a React state update on an unmounted component.*/,
])

//disable logs in production
if (process.env.NODE_ENV === "production") {
    console.debug = () => {}
}

export default function App() {
    const queryClient = new QueryClient()

    return (
        <NativeBaseProvider theme={theme} colorModeManager={colorModeManager}>
            <ErrorBoundary
                onError={(error, info: { componentStack: string }) => {
                    console.debug(error)
                }}
                FallbackComponent={ErrorFallback}
            >
                <QueryClientProvider client={queryClient}>
                    <AuthContextProvider ReloginDialogComponent={ReloginDialog}>
                        <StoreProvider store={store}>
                            <MsgBoxProvider>
                                {/* <EnforceConnexion FallbackComponent={BadConnexionFallback} > */}
                                <InitiatorComponent>
                                    <Navigator />
                                    {/* <TestQuery/> */}
                                </InitiatorComponent>
                            </MsgBoxProvider>
                            {/* </EnforceConnexion> */}
                        </StoreProvider>
                    </AuthContextProvider>

                    {Platform.OS === "web" && <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />}
                </QueryClientProvider>
            </ErrorBoundary>
        </NativeBaseProvider>
    )
}

function InitiatorComponent({ children }: { children: ReactNode }): JSX.Element | null {
    /** APP INIT */
    const [ready, setReady] = useState(false)
    const hydrated = useStoreRehydrated()

    //Init all needed features before spashscreen hides
    const initApp = useCallback(async () => {
        // Prevent native splash screen from autohiding before App component declaration
        await SplashScreen.preventAutoHideAsync()
        await loadFonts()

        setReady(true)
    }, [])

    useEffect(() => {
        initApp()
    }, [initApp])

    useEffect(() => {
        hydrated && ready && SplashScreen.hideAsync()
    }, [hydrated, ready])

    if (!hydrated || !ready) return null
    else return <>{children}</>
}

async function loadFonts() {
    return await Font.loadAsync({
        WorkSans_300Light,
        WorkSans_400Regular,
        WorkSans_500Medium,
        WorkSans_600SemiBold,
    })
}

//TODO: Separer le loading initia de l'app du loading des datas ?
//TODO: Attendre la réhydratation du state pour masquer le spash-screen
//TODO: fetch des listes (matière, niveau, villes dès l'écran de login - pas nécessaire pour  l'app de planning)

// <Center
// _dark={{ bg: "blueGray.900" }}
// _light={{ bg: "blueGray.50" }}
// px={4}
// flex={1}
// >
// <VStack space={5} alignItems="center">
//   <NativeBaseIcon />
//   <Heading size="lg">Welcome to OberSchrumbersteinkrebs
//   </Heading>
//   <HStack space={2} alignItems="center">
//     <Text>Edit</Text>
//     <Code>App.tsx</Code>
//     <Text>and save to reload.</Text>
//   </HStack>
//   <Link href="https://docs.nativebase.io" isExternal>
//     <Text color="primary.500" underline fontSize={"xl"}>
//       Learn NativeBase
//     </Text>
//   </Link>
//   <ToggleDarkMode />
// </VStack>
// </Center>
