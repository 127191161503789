import { Card } from "@core/atoms"
import { useAuthContext } from "@core/context/auth"
import { current_proposition_lessons_query, current_real_lessons_query, match_infos_list_query, user_info_query } from "@core/queries"
import type { LessonAbortReason } from "@core/services/rasmik-client"
import { conditional, EuropeParis } from "@core/utils"
import { formatDurationMin } from "@core/utils/format"
import { FontAwesome5 } from "@expo/vector-icons"
import { max, min } from "lodash"
import { DateTime, Duration } from "luxon"
import { Badge, Box, Center, Divider, Heading, HStack, type IBadgeProps, Text, VStack } from "native-base"
import React from "react"

import { BookedActions } from "./BookedActions"
import { DraftNotAcceptedActions } from "./DraftNotAcceptedActions"
import { SubmittingActions } from "./SubmittingActions"

//TODO: Card indépendante pour chaque lesson.
//Bouton déclarer un cours en bas de page
//1er card = résumé du mois.
//Résumé du mois est un panneau qui se plie avec le scroll.
export function LessonCard({ lessonId, matchId }: { lessonId: number; matchId: number }) {
    const auth = useAuthContext()
    const { currentRealLessonsQuery } = current_real_lessons_query.use({ teacherId: auth.userId }, { staleTime: 5000 })
    const { currentPropositionLessonsQuery } = current_proposition_lessons_query.use({ teacherId: auth.userId }, { staleTime: 5000 })
    const { userInfoQuery } = user_info_query.use({ teacherId: auth.userId })

    const { matchInfosListQuery } = match_infos_list_query.use({ teacherId: auth.userId })
    const matchInfo = matchInfosListQuery.helpers?.findById?.(matchId)
    const lesson = currentRealLessonsQuery.helpers.findLessonById(lessonId) ?? currentPropositionLessonsQuery.helpers.findLessonById(lessonId)

    const StatusMessageEl = useLessonStatusMessage({ lessonId })

    if (!lesson || !matchInfo) return null

    let ActionsEl: React.ReactElement | null = null

    if (lesson.status === "SUBMITTED") {
        const newProcessAfterDateStr = min([
            DateTime.fromJSDate(userInfoQuery.data?.newProcessEnabledAt ?? new Date(0))
                .plus({ days: 7 })
                .toISODate(),
            "2023-01-01",
        ])!
        const newProcessAfterDateDate = DateTime.fromISO(newProcessAfterDateStr).setZone(EuropeParis).plus({ hours: 12 }).toJSDate()

        const defnitelySubmitted = lesson.submittedAt && DateTime.fromJSDate(max([lesson.submittedAt, newProcessAfterDateDate])!).diffNow() < Duration.fromObject({ hours: -12 })

        if (!defnitelySubmitted) {
            ActionsEl = <SubmittingActions lessonId={lessonId} matchId={matchId} />
        }
    } else if (lesson.status === "BOOKED") {
        ActionsEl = <BookedActions lessonId={lessonId} matchId={matchId} />
    } else if (lesson.status === "DRAFT" && !(lesson.teacherAcceptedAt || lesson.teacherForcedAt)) {
        ActionsEl = <DraftNotAcceptedActions lessonId={lessonId} matchId={matchId} />
    }

    const isRealMatch = matchInfo.status === "ACTIVE" || matchInfo.status === "PAUSED" || matchInfo.status === "FINISHED"

    return (
        <Card>
            <HStack justifyContent="space-between">
                <VStack space={2}>
                    <Heading size="sm">
                        {matchInfo.Program.Learner.firstName} {conditional(matchInfo.Program.Learner.lastName ?? "", isRealMatch)}
                    </Heading>
                    <Text fontSize="xs" _light={{ color: "primary.500" }} _dark={{ color: "primary.400" }} fontWeight="500" mt="-1">
                        {matchInfo.label}
                    </Text>
                    <HStack justifyContent="flex-start" alignItems="center">
                        <Text width="170px">
                            {DateTime.fromJSDate(lesson.startedAtOfficial ?? lesson.startedAtPlanned!)
                                .toFormat("EEE dd MMM 'à' H'h'mm")
                                .replace(" à 0h00", "")}
                        </Text>
                        <Text width="60px">{formatDurationMin(lesson.durationMin ?? lesson.durationMinPlanned ?? 0)}</Text>
                    </HStack>
                </VStack>

                <Box mt="23px">
                    <Center width="130px">
                        <LessonStatusChip lessonId={lessonId} />
                    </Center>
                </Box>
            </HStack>

            {StatusMessageEl && <Divider my="10px" />}
            {StatusMessageEl}

            {ActionsEl && <Divider my="10px" />}
            {ActionsEl}
        </Card>
    )
}
function LessonStatusChip({ lessonId }: { lessonId: number }): React.ReactElement | null {
    const auth = useAuthContext()
    const { currentRealLessonsQuery } = current_real_lessons_query.use({ teacherId: auth.userId }, { staleTime: 5000 })
    const { currentPropositionLessonsQuery } = current_proposition_lessons_query.use({ teacherId: auth.userId }, { staleTime: 5000 })

    const lesson = currentRealLessonsQuery.helpers.findLessonById(lessonId) ?? currentPropositionLessonsQuery.helpers.findLessonById(lessonId)
    if (!lesson) return null
    const commonProps: IBadgeProps = { variant: "subtle", rounded: "full" }

    if (lesson.status === "PAYSLIPPED") {
        return (
            <Badge {...commonProps} colorScheme="success" leftIcon={<FontAwesome5 name="lock" />}>
                Transmis
            </Badge>
        )
    } else if (lesson.status === "SUBMITTED") {
        const defnitelySubmitted = lesson.submittedAt && DateTime.fromJSDate(lesson.submittedAt).diffNow() < Duration.fromObject({ hours: -12 })
        if (defnitelySubmitted) {
            return (
                <Badge {...commonProps} colorScheme="success" leftIcon={<FontAwesome5 name="lock" />}>
                    Transmis
                </Badge>
            )
        } else {
            return (
                <Badge {...commonProps} colorScheme="success" leftIcon={<FontAwesome5 name="lock-open" />}>
                    Transmis
                </Badge>
            )
        }
    } else if (lesson.status === "ABORTED") {
        if (!lesson.abortReason) return null
        const abortReasonIconDict: Record<LessonAbortReason, React.ReactElement> = {
            aborted_by_admin: (
                <Badge {...commonProps} colorScheme="trueGray" leftIcon={<FontAwesome5 name="trash" />}>
                    Abandonné
                </Badge>
            ),
            aborted_by_learner: (
                <Badge {...commonProps} colorScheme="trueGray" leftIcon={<FontAwesome5 name="trash" />}>
                    Abandonné
                </Badge>
            ),
            aborted_by_teacher: (
                <Badge {...commonProps} colorScheme="trueGray" leftIcon={<FontAwesome5 name="trash" />}>
                    Abandonné
                </Badge>
            ),
            too_late_learner: (
                <Badge {...commonProps} colorScheme="trueGray" leftIcon={<FontAwesome5 name="clock" />}>
                    Expiré
                </Badge>
            ),
            too_late_both: (
                <Badge {...commonProps} colorScheme="trueGray" leftIcon={<FontAwesome5 name="clock" />}>
                    Expiré
                </Badge>
            ),
            too_late_teacher: (
                <Badge {...commonProps} colorScheme="trueGray" leftIcon={<FontAwesome5 name="clock" />}>
                    Expiré
                </Badge>
            ),
            reproposed_by_admin: (
                <Badge {...commonProps} colorScheme="trueGray" leftIcon={<FontAwesome5 name="refresh" />}>
                    Reproposé
                </Badge>
            ),
            reproposed_by_learner: (
                <Badge {...commonProps} colorScheme="trueGray" leftIcon={<FontAwesome5 name="refresh" />}>
                    Reproposé
                </Badge>
            ),
            reproposed_by_teacher: (
                <Badge {...commonProps} colorScheme="trueGray" leftIcon={<FontAwesome5 name="refresh" />}>
                    Reproposé
                </Badge>
            ),
            counterproposed_by_learner: (
                <Badge {...commonProps} colorScheme="trueGray" leftIcon={<FontAwesome5 name="exchange-alt" />}>
                    Reproposé
                </Badge>
            ),
            counterproposed_by_teacher: (
                <Badge {...commonProps} colorScheme="trueGray" leftIcon={<FontAwesome5 name="exchange-alt" />}>
                    Reproposé
                </Badge>
            ),
            refused_by_learner: (
                <Badge {...commonProps} colorScheme="trueGray" leftIcon={<FontAwesome5 name="hand-paper" />}>
                    Refusé
                </Badge>
            ),
            refused_by_teacher: (
                <Badge {...commonProps} colorScheme="trueGray" leftIcon={<FontAwesome5 name="hand-paper" />}>
                    Refusé
                </Badge>
            ),
        }

        return abortReasonIconDict[lesson.abortReason]
    } else if (lesson.status === "CANCELLED") {
        return (
            <Badge {...commonProps} colorScheme="trueGray" leftIcon={<FontAwesome5 name="times" />}>
                Annulé
            </Badge>
        )
    } else if (lesson.status === "BOOKED") {
        return (
            <Badge {...commonProps} colorScheme="info" leftIcon={<FontAwesome5 name="calendar-alt" />}>
                Prévu
            </Badge>
        )
    } else if (lesson.status === "DRAFT") {
        if (!(lesson.teacherAcceptedAt || lesson.teacherForcedAt)) {
            return (
                <Badge {...commonProps} colorScheme="warning" leftIcon={<FontAwesome5 name="exclamation-circle" />}>
                    Proposition
                </Badge>
            )
        } else {
            return (
                <Badge {...commonProps} colorScheme="warning" leftIcon={<FontAwesome5 name="hourglass-start" />}>
                    Attente élève
                </Badge>
            )
        }
    } else {
        return null
    }
}

function useLessonStatusMessage({ lessonId }: { lessonId: number }): React.ReactElement | null {
    const auth = useAuthContext()
    const { currentRealLessonsQuery } = current_real_lessons_query.use({ teacherId: auth.userId }, { staleTime: 5000 })
    const { currentPropositionLessonsQuery } = current_proposition_lessons_query.use({ teacherId: auth.userId }, { staleTime: 5000 })

    const lesson = currentRealLessonsQuery.helpers.findLessonById(lessonId) ?? currentPropositionLessonsQuery.helpers.findLessonById(lessonId)
    if (!lesson) return null

    if (lesson.status === "ABORTED") {
        if (!lesson.abortReason) return null
        const abortReasonIconDict: Record<LessonAbortReason, string> = {
            aborted_by_admin: "Pedagome a supprimé cette proposition",
            aborted_by_learner: "L'élève a abandonné sa proposition",
            aborted_by_teacher: "Tu as abandonné cette proposition de cours",
            too_late_learner: "L'élève n'a pas accepté dans les temps",
            too_late_both: "N'a pas été accepté dans les temps",
            too_late_teacher: "Tu n'as pas accepté dans les temps",
            reproposed_by_admin: "Pedagome a proposé un nouvel horaire",
            reproposed_by_learner: "L'élève a proposé un nouvel horaire",
            reproposed_by_teacher: "Tu as proposé un nouvel horaire",
            counterproposed_by_learner: "L'élève a proposé un nouvel horaire",
            counterproposed_by_teacher: "Tu as proposé un nouvel horaire",
            refused_by_learner: "L'élève a refusé cette proposition de cours",
            refused_by_teacher: "Tu as refusé cette proposition de cours",
        }
        return <Text>{abortReasonIconDict[lesson.abortReason]}</Text>
    } else if (lesson.status === "CANCELLED") {
        if (lesson.cancelledBy === "admin") {
            return <Text>Le cours était prévu mais tu l'as annulé</Text>
        } else if (lesson.cancelledBy === "learner") {
            return <Text>Le cours était prévu mais l'élève l'a annulé</Text>
        } else {
            return <Text>Le cours était prévu mais un conseiller Pedagome l'a annulé</Text>
        }
    } else {
        return null
    }
}
