import { useAuthContext } from "@core/context/auth"
import { type LinkingOptions, NavigationContainer } from "@react-navigation/native"
import { createStackNavigator } from "@react-navigation/stack"
import * as Linking from "expo-linking"
import { useColorMode } from "native-base"
import React from "react"
import { ActivityIndicator } from "react-native"

import { AuthStack } from "../../auth-stack/AuthStack"
import { LoginResetScreen } from "../../auth-stack/LoginResetScreen"
import { MainStack } from "../../new-stack/MainStack"
import { useEzState } from "../store"
import { useNavigationTheme } from "./theme"

// import { MsgBox } from '../components/MsgBox';

/**
 * Base de @react-navigation
 * C'est ici qu'on set le theme propre à @react-navigation. Ce theme est utilisé pour le background de l'app et les tabs
 *  */

const GlobNav = createStackNavigator<GlobStackParamsList>()
export type GlobStackParamsList = {
    AuthStack: undefined
    MainStack: undefined
    LoginReset: { login?: string; password?: string }
}

export function Navigator() {
    const { colorMode } = useColorMode()
    const navigationTheme = useNavigationTheme(colorMode)
    const auth = useAuthContext()
    const networkErrorScreenVisible = useEzState((s) => s.networkErrorScreenVisible)

    const linking: LinkingOptions<any> = {
        prefixes: [Linking.createURL("/"), "https://tuteurs.pedagome.fr"],
        config: {
            screens: {
                AuthStack: {
                    // path: '',
                    // exact: true,
                    screens: {
                        Login: { exact: true, path: "login" },
                        ForgetPassword: { exact: true, path: "oubli-mdp" },
                    },
                },
                MainStack: {
                    screens: {
                        Messages: { exact: true, path: "notifications" },
                        EditSurvey: { exact: true, path: "edition-bilan" },
                        Payslips: { exact: true, path: "bulletins-de-paie" },
                        Tuto: { exact: true, path: "tuto" },
                        Tuto_Auto: { exact: true, path: "tuto-auto" },

                        AddLessonSelectMatch: { exact: true, path: "choix-match" },
                        NewProcess: { exact: true, path: "nouveau-mode-de-saisie" },
                        MainTabs: {
                            // path: '',
                            // exact: true,
                            screens: {
                                SaisieHeures: { exact: true, path: "heures" },
                                SurveysList: { exact: true, path: "bilans" },
                                MatchList: { exact: true, path: "matchs" },
                                User: { exact: true, path: "profil" },
                            },
                        },
                        EditInfoForm: { exact: true, path: "mes-infos" },
                        SplashNotifications: { exact: true, path: "splash" },
                        EditScolarityForm: { exact: true, path: "mes-études" },
                        EditYearDispoForm: { exact: true, path: "mes-dispos-annuelles" },
                        EditWeekDispoForm: { exact: true, path: "mes-dispos-hebdo" },
                        TimePickerScreen: { exact: true, path: "sélection-plage" },
                    },
                },
                LoginReset: { exact: true, path: "login-reset" },
            },
        },
    }

    return (
        <NavigationContainer
            linking={linking}
            fallback={<ActivityIndicator color="blue" size="large" />}
            theme={navigationTheme}
            documentTitle={{
                formatter: (options, route) => (options?.title ? `Pedagome | ${options?.title}` : "Pedagome"),
            }}
        >
            <GlobNav.Navigator screenOptions={{ headerShown: false }} initialRouteName="MainStack" detachInactiveScreens>
                {/* {networkErrorScreenVisible && <BadConnexionFallback />} */}
                {/* {!networkErrorScreenVisible && auth.isLoggedIn === false && <AuthStack />} */}
                {/* {!networkErrorScreenVisible && <MainStack />} */}

                <GlobNav.Screen name="MainStack" component={MainStack} />
                <GlobNav.Screen name="AuthStack" component={AuthStack} />
                <GlobNav.Screen name="LoginReset" component={LoginResetScreen} />
            </GlobNav.Navigator>

            {/* <MsgBox/> */}
        </NavigationContainer>
    )
}
