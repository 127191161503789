import { Card } from "@core/atoms/Card"
import { useAuthContext } from "@core/context/auth"
import type { useAccordion } from "@core/hooks/useAccordion"
import type { MatchInfoData } from "@core/queries/match_infos_list"
import { DayDate } from "@core/utils/DayDate"
import { age, formatDurationMin, formatEuro, frequencyToText } from "@core/utils/format"
import { openAddressInMaps, openPhoneNumber } from "@core/utils/openLinks"
import { conditional, plural } from "@core/utils/textUtils"
import { toFrenchRelativeCalendar } from "@core/utils/toFrenchRelativeCalendar"
import { FontAwesome5 } from "@expo/vector-icons"
import type { NavigationProp } from "@react-navigation/native"
import type { StackNavigationProp } from "@react-navigation/stack"
import { isNil } from "lodash"
import { DateTime } from "luxon"
import { Badge, Box, Button, Center, ChevronDownIcon, ChevronUpIcon, Divider, Heading, HStack, Icon, IconButton, Stack, Text, useColorModeValue, useTheme, useToken, VStack, Alert, Hidden } from "native-base"
import type { ReactNode } from "react"
import Collapsible from "react-native-collapsible"

import type { MainStackParamsList, MainTabsParamList } from "../MainStack"

export interface MatchListCardProps {
    matchInfo: MatchInfoData
    isCollapsed: boolean
    expand: ReturnType<typeof useAccordion>["expand"]
    collapse: ReturnType<typeof useAccordion>["collapse"]
    listIndex: number
    navigation: StackNavigationProp<MainTabsParamList, "MatchList">
}
export function MatchListCard(props: MatchListCardProps) {
    const { matchInfo, isCollapsed = true, collapse, expand, listIndex, navigation } = props

    const isReal = matchInfo.status === "ACTIVE" || matchInfo.status === "PAUSED" || matchInfo.status === "FINISHED"
    const auth = useAuthContext()
    let bottomInfoText = ""
    let badge: ReactNode
    if (matchInfo.status === "ACTIVE") {
        badge = (
            <Badge nativeID="badge" colorScheme="success" rounded="full" variant="subtle" leftIcon={<FontAwesome5 name="superpowers" />}>
                Actif
            </Badge>
        )
        bottomInfoText = matchInfo.lastLessonAt ? `Dernier cours ${toFrenchRelativeCalendar(DateTime.fromJSDate(matchInfo.lastLessonAt))}` : "Nouveau"
    } else if (matchInfo.status === "PAUSED") {
        badge = (
            <Badge nativeID="badge" colorScheme="trueGray" rounded="full" variant="subtle" leftIcon={<FontAwesome5 name="pause" />}>
                En pause
            </Badge>
        )
        bottomInfoText = matchInfo.lastLessonAt ? `Dernier cours ${toFrenchRelativeCalendar(DateTime.fromJSDate(matchInfo.lastLessonAt))}` : "Aucun cours"
    } else if (matchInfo.status === "FINISHED") {
        badge = (
            <Badge nativeID="badge" colorScheme="trueGray" rounded="full" variant="subtle">
                Terminé
            </Badge>
        )
        bottomInfoText = matchInfo.lastLessonAt ? `Dernier cours ${toFrenchRelativeCalendar(DateTime.fromJSDate(matchInfo.lastLessonAt))}` : "Aucun cours"
    } else if (matchInfo.status === "DRAFT" && matchInfo.Teacher === auth.userId && !matchInfo.customerActivatedAt) {
        bottomInfoText = "Tu as accepté la proposition mais l'élève doit aussi la valider"
        badge = (
            <Badge nativeID="badge" colorScheme="warning" rounded="full" variant="subtle" leftIcon={<FontAwesome5 name="hourglass-start" />}>
                Attente élève
            </Badge>
        )
    } else if (matchInfo.status === "DRAFT" && matchInfo.Teacher === auth.userId && matchInfo.customerActivatedAt) {
        bottomInfoText = "Tu as accepté la proposition mais le 1er cours n'est pas encore convenu"
        badge = (
            <Badge nativeID="badge" colorScheme="warning" rounded="full" variant="subtle" leftIcon={<FontAwesome5 name="hourglass-start" />}>
                Prévoir 1er cours
            </Badge>
        )
    } else if (matchInfo.status === "ABANDONNED") {
        bottomInfoText = "La proposition de match a été supprimée par un conseiller Pedagome"
        badge = (
            <Badge nativeID="badge" colorScheme="trueGray" rounded="full" variant="subtle" leftIcon={<FontAwesome5 name="trash" />}>
                Abandonné
            </Badge>
        )
    }

    const theme = useTheme()
    const iconColor = useColorModeValue(theme.colors.light[800], theme.colors.dark[800])
    const hasDetailPanel = matchInfo.status === "ACTIVE" || matchInfo.status === "DRAFT"
    return (
        <Card py="10px">
            <HStack /* p="4"  */ space={3} alignItems="center">
                <Stack flex={1}>
                    <Heading size="md">
                        {matchInfo?.Program?.Learner?.firstName || ""} {conditional(matchInfo?.Program?.Learner?.lastName ?? "", isReal)}
                    </Heading>
                    <Text mt="2px" fontSize="xs" _light={{ color: "primary.500" }} _dark={{ color: "primary.400" }} fontWeight="500">
                        {matchInfo.label}
                    </Text>
                </Stack>

                <Center width="120px">{badge}</Center>

                <Box>
                    {isCollapsed && hasDetailPanel && <IconButton onPress={() => expand(listIndex)} mt="2px" variant="ghost" icon={<ChevronDownIcon size="md" color={iconColor} />} />}
                    {!isCollapsed && hasDetailPanel && <IconButton onPress={() => collapse(listIndex)} mt="2px" variant="ghost" icon={<ChevronUpIcon size="md" color={iconColor} />} />}
                    {!hasDetailPanel && <Box w="40px" h="42px" />}
                </Box>
            </HStack>

            <Text fontSize="xs" mt="5px" color="coolGray.600" _dark={{ color: "warmGray.200" }} fontWeight="400">
                {bottomInfoText}
            </Text>

            {hasDetailPanel && (
                <Collapsible collapsed={isCollapsed} /* style={{ maxWidth: useToken('breakpoints', '2xl') }} */>
                    {matchInfo.status === "ACTIVE" && <DetailPanel matchInfo={matchInfo} navigation={navigation} />}
                    {matchInfo.status === "DRAFT" && <DraftDetailPanel matchInfo={matchInfo} />}
                </Collapsible>
            )}
        </Card>
    )
}

//Cas cours
//Bouton historique
//Si en attente, en attente depuis le
//Si terminé ou en pause, la date du dernier cours suffit
//1er cours
//Dernier cours
//Jour préféré

//Cas tprop
//Bouton signer (en évidence)
//Infos signature client en attente / ok

export function DetailPanel(props: { matchInfo: MatchInfoData; navigation: StackNavigationProp<MainTabsParamList, "MatchList"> }) {
    const { matchInfo, navigation } = props
    const learnerFullName = `${matchInfo?.Program?.Learner?.firstName || ""} ${matchInfo?.Program?.Learner?.lastName || ""}`
    return (
        <Box key={matchInfo.id}>
            <DetailSection title="Durée totale">
                <HStack justifyContent="space-between" alignItems="center">
                    <Text fontSize="lg">{formatDurationMin(matchInfo?.lessonsDuration)}</Text>
                    <Button colorScheme="pedagome" variant="subtle2" onPress={() => navigation.getParent<NavigationProp<MainStackParamsList, "History">>().navigate("History", { matchId: matchInfo.id })}>
                        Historique
                    </Button>
                </HStack>
            </DetailSection>

            <DetailSection title={`${plural("Adresse", "Adresses", matchInfo?.Program?.Learner?.LessonAddresses?.length)} des cours`}>
                <VStack space="15px" key={matchInfo.id}>
                    {matchInfo?.Program?.Learner?.LessonAddresses?.map((address) => {
                        return (
                            <Box key={address.id} alignItems="center" width="full" justifyContent="center" display="flex" flexDirection="row">
                                <Text flex={1}>
                                    {[address.fullStreet, address.postalCode, address.cityName].join(", ")}
                                    {address.role ? (
                                        <>
                                            {"\n"}({address.role})
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </Text>
                                <IconButton
                                    onPress={() => openAddressInMaps(address, learnerFullName)}
                                    mt="2px"
                                    /* variant={"ghost"} */ icon={<Icon as={FontAwesome5} name="map-marked-alt" _light={{ color: "darkText" }} _dark={{ color: "lightText" }} size="sm" />}
                                />
                            </Box>
                        )
                    })}
                </VStack>
            </DetailSection>

            <DetailSection title={`N° de ${plural("téléphone", "téléphones", matchInfo?.Program?.Learner?.familyPhoneNumbers?.length)}`} hide={!matchInfo?.Program?.Learner?.familyPhoneNumbers?.length}>
                {matchInfo?.Program?.Learner?.familyPhoneNumbers
                    ?.sort((a, b) => (a.isOwner ? -1 : 1))
                    .map((pn) => {
                        return (
                            <VStack key={pn.id} space="15px">
                                <Box alignItems="center" key={pn.id} width="full" justifyContent="center" display="flex" flexDirection="row">
                                    <Text flex={1}>
                                        {pn.formatedNumber} - {pn.isOwner ? "élève" : `${pn.ownerFirstName} ${pn.ownerLastName}${conditional(` (${pn.ownerMemberRole})`, !!pn.ownerMemberRole)}`}
                                    </Text>
                                    <IconButton
                                        onPress={() => openPhoneNumber(pn.number)}
                                        mt="2px"
                                        /* variant={"ghost"} */ icon={<Icon as={FontAwesome5} name="phone" _light={{ color: "darkText" }} _dark={{ color: "lightText" }} size="sm" />}
                                    />
                                </Box>
                            </VStack>
                        )
                    })}
            </DetailSection>

            <DetailSection
                title="Scolarité"
                hide={Boolean(!matchInfo?.Program?.Learner?.scolarityClassLabel || (matchInfo?.Program?.Learner?.scolarityExpireOn && matchInfo?.Program?.Learner?.scolarityExpireOn < DayDate.today().toISOString()))}
            >
                <Text>
                    {matchInfo?.Program?.Learner?.scolarityClassLabel}
                    {conditional(` (${matchInfo?.Program?.Learner?.scolaritySchool})`, !!matchInfo?.Program?.Learner?.scolaritySchool)}
                </Text>
            </DetailSection>

            {!!matchInfo?.Program?.Learner?.birthDate && DateTime.fromISO(matchInfo.Program.Learner.birthDate).isValid && (
                <DetailSection title="Date de naissance">
                    <Text>
                        {DateTime.fromISO(matchInfo.Program.Learner.birthDate).toFormat("dd/MM/yyyy")} - {age(DayDate.fromISOString(matchInfo.Program.Learner.birthDate)!)}
                    </Text>
                </DetailSection>
            )}

            <DetailSection title="Objectifs" hide={!matchInfo.Program?.goals}>
                <Text>{matchInfo?.Program?.goals}</Text>
            </DetailSection>

            <DetailSection title="Moyenne" hide={!matchInfo.Program?.initialMean}>
                <Text>{matchInfo.Program?.initialMean?.toFixed(0)}</Text>
            </DetailSection>

            {matchInfo.Program?.wishedDurationMin && matchInfo.Program?.wishedFrequency && (
                <DetailSection title="Durée et fréquence souhaitée (a titre indicatif)" hide={!matchInfo.Program.wishedDurationMin || !matchInfo.Program.wishedFrequency}>
                    <Text>
                        {formatDurationMin(matchInfo.Program.wishedDurationMin)} {frequencyToText(matchInfo.Program.wishedFrequency)}
                    </Text>
                </DetailSection>
            )}

            <DetailSection title="Rémunération">
                <Text>{formatEuro(matchInfo?.baseRem)} /h</Text>
            </DetailSection>

            <DetailSection title="Commentaires" hide={!matchInfo.selectedTeacherProposition?.teacherInstructions}>
                <Text>{matchInfo.selectedTeacherProposition?.teacherInstructions}</Text>
            </DetailSection>
        </Box>
    )
}

interface DetailSectionProps {
    title: string
    children: ReactNode | ReactNode[]
    hide?: boolean
    noDivider?: boolean
}
function DetailSection(props: DetailSectionProps) {
    const { children, title, hide, noDivider } = props
    const textColor = useColorModeValue("light.600", "dark.600")
    if (hide) return null
    return (
        <>
            {!noDivider && <Divider my="15px" />}

            <Text fontSize="xs" mb="3px" color={textColor}>
                {title}
            </Text>
            {children}
        </>
    )
}

export function DraftDetailPanel({ matchInfo }: { matchInfo: MatchInfoData }) {
    const auth = useAuthContext()
    // const { propositionsListQuery } = propositions_list_query.use({ teacherId: auth.userId })
    // const tprop = propositionsListQuery.helpers.getPropById(propId)
    const match = matchInfo
    const theme = useTheme()
    const iconColor = useColorModeValue(theme.colors.light[800], theme.colors.dark[800])

    return (
        <Box>
            {/* <DetailSection title="Durée totale">
                <HStack justifyContent="space-between" alignItems="center">
                    <Text fontSize="lg">{formatDurationMin(match?.lessonsDuration)}</Text>
                    <Button colorScheme="pedagome" variant="outline" onPress={() => navigation.getParent<NavigationProp<MainStackParamsList, "History">>().navigate('History', { matchId: match.id })} >Historique</Button>
                </HStack>
            </DetailSection> */}

            <DetailSection title={`${plural("Adresse approximative", "Adresses approximatives", match?.Program?.Learner?.LessonAddresses?.length ?? 0)}`}>
                <VStack space="15px">
                    {match?.Program?.Learner?.LessonAddresses?.map((address) => {
                        return (
                            <Box key={address.id} alignItems="center" width="full" justifyContent="center" display="flex" flexDirection="row">
                                <Text flex={1}>
                                    {`${address.streetTypeLabel} ${address.streetName} à ${address.cityName}`.replaceAll("  ", " ")}
                                    {address.role ? (
                                        <>
                                            {"\n"}({address.role})
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </Text>
                                <IconButton
                                    onPress={() =>
                                        openAddressInMaps({
                                            cityName: address.cityName,
                                            fullStreet: `${address.streetTypeLabel} ${address.streetName}`.replaceAll("  ", " "),
                                            postalCode: address.postalCode,
                                        })
                                    }
                                    mt="2px"
                                    /* variant={"ghost"} */ icon={<FontAwesome5 name="map-marked-alt" color={iconColor} size={18} />}
                                />
                            </Box>
                        )
                    })}
                </VStack>
                <Alert mt="10px" variant="subtle2" colorScheme="gray">
                    <Text colorScheme="gray">Les coordonnées exactes seront visibles une fois que l'élève aura finalisé son inscription.</Text>
                </Alert>
            </DetailSection>

            <DetailSection
                title="Scolarité"
                hide={Boolean(!match?.Program?.Learner?.scolarityClassLabel || (match?.Program?.Learner?.scolarityExpireOn && match?.Program?.Learner?.scolarityExpireOn < DayDate.today().toISOString()))}
            >
                <Text>
                    {match?.Program?.Learner?.scolarityClassLabel}
                    {conditional(` (${match?.Program?.Learner?.scolaritySchool})`, !!match?.Program?.Learner?.scolaritySchool)}
                </Text>
            </DetailSection>

            {!!match?.Program?.Learner?.birthDate && DateTime.fromISO(match.Program.Learner.birthDate).isValid && (
                <DetailSection title="Date de naissance" hide={!match.Program.Learner.birthDate}>
                    <Text>
                        {DateTime.fromISO(match.Program.Learner.birthDate).toFormat("MMMM yyyy").toLowerCase()} - {age(DayDate.fromISOString(match.Program.Learner.birthDate)!)}
                    </Text>
                </DetailSection>
            )}

            <DetailSection title="Objectifs" hide={!match?.Program?.goals}>
                <Text>{match?.Program?.goals}</Text>
            </DetailSection>

            <DetailSection title="Moyenne" hide={!match?.Program?.initialMean}>
                <Text>{match?.Program?.initialMean?.toFixed(0)}</Text>
            </DetailSection>

            {matchInfo.Program?.wishedDurationMin && matchInfo.Program?.wishedFrequency && (
                <DetailSection title="Durée et fréquence souhaitée (a titre indicatif)" hide={!matchInfo.Program.wishedDurationMin || !matchInfo.Program.wishedFrequency}>
                    <Text>
                        {formatDurationMin(matchInfo.Program.wishedDurationMin)} {frequencyToText(matchInfo.Program.wishedFrequency)}
                    </Text>
                </DetailSection>
            )}

            <DetailSection title="Rémunération">
                <Text>{formatEuro(matchInfo?.baseRem)} /h</Text>
            </DetailSection>

            <DetailSection title="Commentaires" hide={!matchInfo.selectedTeacherProposition?.teacherInstructions}>
                <Text>{matchInfo.selectedTeacherProposition?.teacherInstructions}</Text>
            </DetailSection>
        </Box>
    )
}
