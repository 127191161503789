import { Card } from "@core/atoms/Card"
import { useAuthContext } from "@core/context/auth"
import { useAccordion } from "@core/hooks/useAccordion"
import { useQueryWithClient } from "@core/hooks/useQueryWithClient"
import { ContentContainer, ContentContainerRow } from "@core/misc-components/ContentContainer"
import { AppBar } from "@core/molecules/AppBar"
import { LoadingScreen } from "@core/organism/LoadingScreen"
import { PayslipGroup, rasmik } from "@core/services/rasmik"
import { formatDurationMin, formatEuro } from "@core/utils/format"
import { conditional } from "@core/utils/textUtils"
import { FontAwesome5 } from "@expo/vector-icons"
import type { StackScreenProps } from "@react-navigation/stack"
import * as FileSystem from "expo-file-system"
import * as Sharing from "expo-sharing"
import { capitalize, isNil, sortBy } from "lodash"
import { DateTime } from "luxon"
import { Box, Center, Divider, HStack, Heading, IconButton, Stack, Text, VStack, useBreakpointValue, useColorModeValue } from "native-base"
import React from "react"
import { Linking, Platform, ScrollView } from "react-native"

import type { MainStackParamsList } from "../MainStack"

export function PayslipsScreen({ navigation }: StackScreenProps<MainStackParamsList, "Payslips">) {
    const auth = useAuthContext()

    const { expandedIndex, expand, collapse } = useAccordion({ multiple: false })

    const tabBarPosition: "top" | "bottom" = useBreakpointValue({
        base: "bottom",
        md: "top",
    })

    const greyTextColor = useColorModeValue("light.600", "dark.600")

    const payslipsQuery = useQueryWithClient(
        ["payslip-list"],
        async () => {
            console.debug("payslipsQuery")
            await auth.ensureValidToken()
            return await rasmik
                .readMany(PayslipGroup)
                .where({ Match: { Teacher: auth.userId } })
                .options({
                    orderBy: { month: "desc" },
                    children: {
                        CurrentPayslip: {
                            children: {
                                Lessons: true,
                            },
                        },
                        Match: {
                            children: {
                                Program: {
                                    children: {
                                        Learner: true,
                                    },
                                },
                            },
                        },
                    },
                })
                .run()
        },
        { enabled: !isNil(auth.userId), useErrorBoundary: true }
    )

    if (isNil(auth) || isNil(auth.userId) || payslipsQuery.status === "loading") return <LoadingScreen />
    if (payslipsQuery.status === "error")
        return (
            <Center h="100%">
                <Text>Impossible de charger la liste</Text>
            </Center>
        )

    const payslipGroups = payslipsQuery.data
    return (
        <VStack safeAreaBottom width="100%" h="100%" display="flex" alignItems="stretch" justifyContent="flex-start">
            <AppBar onBack={navigation.goBack}>
                <ContentContainerRow flex={1}>
                    <Heading size="md">Bulletins de paie</Heading>
                </ContentContainerRow>
            </AppBar>

            <ScrollView style={{ width: "100%", height: 0 }} contentContainerStyle={{ flexGrow: 1, width: "100%" }}>
                <ContentContainer space="15px" paddingY="15px" alignItems="center">
                    <Text>Sauf situation particulière, ou planning soumis en retard, les salaires sont versés avant le 10 du mois.</Text>

                    {payslipGroups.map((payslipGroup, tpropMatchIndex) => {
                        const payslip = payslipGroup.CurrentPayslip!
                        const lessons = sortBy(payslip.Lessons, (lsn) => lsn.startedAtOfficial)
                        const beforeArrondiMin = payslip.treatedLessonDurationMin + payslip.oldRestraintMin + payslip.bonusMin
                        const hasArrondi = beforeArrondiMin % 60 !== 0
                        return (
                            <Card py="10px" key={payslipGroup.id}>
                                <HStack space={3} alignItems="center">
                                    <Stack flex={1}>
                                        <Heading fontSize="md">
                                            {capitalize(DateTime.fromISO(payslipGroup.month).toFormat("MMMM yyyy"))}
                                            {conditional(` - V${payslip.version}`, payslip.version >= 2)}
                                        </Heading>
                                        <Heading size="md">
                                            {payslipGroup.Match?.Program?.Learner?.firstName || ""} {payslipGroup.Match?.Program?.Learner?.lastName || ""}
                                        </Heading>
                                        <Text
                                            mt="2px"
                                            fontSize="xs"
                                            // _light={{ color: "primary.500" }}
                                            // _dark={{ color: "primary.400" }}
                                            fontWeight="500"
                                        >
                                            {payslipGroup.Match.label}
                                        </Text>
                                    </Stack>

                                    <Box>
                                        <IconButton
                                            onPress={() =>
                                                downloadPayslip({
                                                    auth,
                                                    payslipId: payslip.id,
                                                    mode: "download",
                                                    fileName: `${payslipGroup.reference} - ${auth.tokenPayload!.user!.lastName.toUpperCase()} _ ${payslipGroup.Match?.Program?.Learner?.lastName?.toUpperCase()}.pdf`,
                                                })
                                            }
                                            mt="2px"
                                            /* variant={"ghost"} */ icon={<FontAwesome5 name="download" _light={{ color: "darkText" }} _dark={{ color: "lightText" }} size={18} />}
                                        />
                                    </Box>
                                </HStack>

                                <Divider my="5px" />
                                <Text fontSize="xs" mb="3px" color={greyTextColor}>
                                    Cours inclus dans le bulletin
                                </Text>

                                {lessons
                                    .filter((lsn) => lsn.durationMin && lsn.durationMin > 0 && lsn.startedAtOfficial)
                                    .map((lsn) => (
                                        <HStack key={lsn.id} alignItems="center" justifyContent="space-between">
                                            <Text>
                                                {DateTime.fromJSDate(lsn.startedAtUnofficial! ?? lsn.startedAtOfficial!).toFormat("EEE dd MMM")}
                                                {conditional(` (brouillon)`, lsn.status === "DRAFT")}
                                            </Text>
                                            <Text>{formatDurationMin(lsn.durationMin!)}</Text>
                                        </HStack>
                                    ))}

                                <Divider my="5px" />
                                {/* <Text fontSize="xs" mb="3px" color={greyTextColor}>Heures comptabilisées</Text> */}

                                <HStack alignItems="center" justifyContent="space-between">
                                    <Text fontSize="sm" textAlign="center">
                                        Durée des cours
                                    </Text>
                                    <Text fontSize="sm" textAlign="center">
                                        {formatDurationMin(payslip.treatedLessonDurationMin)}
                                    </Text>
                                </HStack>

                                {!!payslip.bonusMin && (
                                    <HStack alignItems="center" justifyContent="space-between">
                                        <Text fontSize="sm" textAlign="center">
                                            Bonus
                                        </Text>
                                        <Text fontSize="sm" textAlign="center">
                                            {formatDurationMin(payslip.bonusMin)}
                                        </Text>
                                    </HStack>
                                )}

                                {!!payslip.oldRestraintMin && (
                                    <HStack alignItems="center" justifyContent="space-between">
                                        <Text fontSize="sm" textAlign="center">
                                            Corection due aux arrondis des mois précédents
                                        </Text>
                                        <Text fontSize="sm" textAlign="center">
                                            {(payslip.oldRestraintMin > 0 ? "+" : "") + formatDurationMin(payslip.oldRestraintMin)}
                                        </Text>
                                    </HStack>
                                )}

                                {hasArrondi && (
                                    <HStack alignItems="center" justifyContent="space-between">
                                        <Text fontSize="sm" textAlign="center">
                                            Durée avant arrondi
                                        </Text>
                                        <Text fontSize="sm" textAlign="center">
                                            {formatDurationMin(payslip.treatedLessonDurationMin + payslip.oldRestraintMin + payslip.bonusMin)}
                                        </Text>
                                    </HStack>
                                )}

                                <HStack alignItems="center" justifyContent="space-between">
                                    <Text color="primary.400" fontWeight={600} fontSize="sm" textAlign="center">
                                        Durée sur le bulletin{conditional(" (après arrondi)", hasArrondi)}
                                    </Text>
                                    <Text color="primary.400" fontWeight={600} fontSize="sm" textAlign="center">
                                        {payslip.nbH}h
                                    </Text>
                                </HStack>

                                {!!payslip.newRestraintMin && (
                                    <HStack alignItems="center" justifyContent="space-between">
                                        <Text fontSize="sm" textAlign="center">
                                            Correction à appliquer aux mois suivants
                                        </Text>
                                        <Text fontSize="sm" textAlign="center">
                                            {(payslip.newRestraintMin > 0 ? "+" : "") + formatDurationMin(payslip.newRestraintMin)}
                                        </Text>
                                    </HStack>
                                )}

                                <Divider my="5px" />

                                <HStack alignItems="center" justifyContent="space-between">
                                    <Text fontSize="xl" mb="3px" color={greyTextColor}>
                                        Montant du versement
                                    </Text>
                                    <Text fontSize="xl" textAlign="center">
                                        {formatEuro(payslip.remTot)}
                                    </Text>
                                </HStack>
                            </Card>
                        )
                    })}
                </ContentContainer>
            </ScrollView>
        </VStack>
    )
}

function getFileUri(name: string) {
    return FileSystem.cacheDirectory + `${encodeURI(name)}`
}

async function downloadPayslip(payload: { payslipId: number; mode: "preview" | "download"; fileName: string; auth: ReturnType<typeof useAuthContext> }) {
    const { payslipId, mode, fileName, auth } = payload

    await auth.ensureValidToken()
    const token = auth.token
    const remoteFileUrl = `${process.env.DOPPLE_API_URL}/pdfs/payslip/${payslipId}?token=${token}`

    if (Platform.OS === "web") {
        Linking.openURL(remoteFileUrl)
    } else if (Platform.OS === "android" || Platform.OS === "ios") {
        // const res = await axios.get(`${process.env.DOPPLE_API_URL}/pdfs/payslip/${payslipId}`, { responseType: "blob", headers: { ...authHeader() } })
        // const fileData = res.data

        // const contentDisposition: string = res.headers["content-disposition"]
        // const fileName = contentDisposition.replace("attachment; filename=", "")

        // //Convert to base64
        // const base64 = Buffer.from(fileData, 'binary').toString('base64')

        // const fileUri = getFileUri(fileName);
        // await FileSystem.writeAsStringAsync(fileUri, base64, { encoding: FileSystem.EncodingType.Base64 });
        // await Sharing.shareAsync(fileUri);

        const fileUri = getFileUri(fileName)
        const { uri } = await FileSystem.downloadAsync(remoteFileUrl, fileUri)
        await Sharing.shareAsync(fileUri)
    }
}
