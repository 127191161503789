import { ScrollViewGrow } from "@core/atoms"
import { useAuthContext } from "@core/context/auth"
import { useCatchToBoundary, useSpinner } from "@core/hooks"
import { ContentContainer, ContentContainerColumn } from "@core/misc-components/ContentContainer"
import { LoadingScreen } from "@core/organism/LoadingScreen"
import { current_real_lessons_query, match_infos_list_query } from "@core/queries"
import { current_proposition_lessons_query } from "@core/queries/current_proposition_lessons"
import { de_, formatDurationMin, getShowedMonthDt } from "@core/utils"
import { FontAwesome5 } from "@expo/vector-icons"
import type { NavigationProp } from "@react-navigation/native"
import type { StackScreenProps } from "@react-navigation/stack"
import { sortBy, sumBy } from "lodash"
import { DateTime } from "luxon"
import { Box, Button, Center, Divider, Heading, HStack, Icon, Text, useColorModeValue, useTheme, VStack } from "native-base"
import React, { useRef } from "react"
import { Animated, RefreshControl, ScrollView } from "react-native"

import { LessonCard } from "./LessonCard"
import type { MainStackParamsList, MainTabsParamList } from "../MainStack"

const BAR_HEIGHT = 60
const SCROLL_ANIMATION_HEIGHT = 60
/**
 * Liste des matchs actifs du mois en cours
 * Pour chaque match, liste des cours saisis + bouton d'ajout de créneau
 * Possibilité d'annuler une saisie sous 12h
 */
export function SaisieScreen({ navigation }: StackScreenProps<MainTabsParamList, "SaisieHeures">) {
    const auth = useAuthContext()
    const { matchInfosListQuery } = match_infos_list_query.use({ teacherId: auth.userId })

    const { currentRealLessonsQuery } = current_real_lessons_query.use({ teacherId: auth.userId }) //to prefetch
    const { currentPropositionLessonsQuery } = current_proposition_lessons_query.use({ teacherId: auth.userId }) //to prefetch

    const scrollRefreshSpinner = useSpinner()
    const onRefresh = useCatchToBoundary(async () => {
        console.debug("SaisieScreen scroll refresh")
        try {
            await auth.ensureValidToken()
            scrollRefreshSpinner.start()
            await matchInfosListQuery.refetch()
        } finally {
            scrollRefreshSpinner.stop()
        }
    }, [auth, scrollRefreshSpinner, matchInfosListQuery])

    const onScrollAnim = useRef(new Animated.Value(0)).current

    const theme = useTheme()
    const barBg = useColorModeValue(theme.colors.appBar.light, theme.colors.appBar.dark)

    if (!matchInfosListQuery.data?.currentMonthMatches || matchInfosListQuery.isLoading) return <LoadingScreen />

    const allRealLessons = currentRealLessonsQuery.helpers.getAllLessons()
    const allPropositionLessons = currentPropositionLessonsQuery.helpers.getAllLessons()

    let lessons = [...allRealLessons, ...allPropositionLessons]
    lessons = sortBy(lessons, (lsn) => lsn.startedAtOfficial ?? lsn.startedAtPlanned).reverse()
    // console.log({ allPropositionLessons, lessons })

    const hasCurrentMatches = !!matchInfosListQuery.data.currentMonthMatches.length

    const showNoLessonsMessage = !lessons.length && !currentRealLessonsQuery.isLoading && !currentPropositionLessonsQuery.isLoading
    return (
        <VStack safeAreaBottom width="100%" h="100%">
            {showNoLessonsMessage ? (
                <NoLessonsMessage />
            ) : (
                <ScrollView
                    style={{ width: "100%", height: 0 }}
                    contentContainerStyle={{ flexGrow: 1, width: "100%", alignItems: "stretch" }}
                    refreshControl={<RefreshControl refreshing={scrollRefreshSpinner.loading} onRefresh={onRefresh} />}
                    onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: onScrollAnim } } }], { useNativeDriver: false })}
                    scrollEventThrottle={16}
                >
                    <Box shadow={1} padding="15px" backgroundColor="appBar.light" _dark={{ backgroundColor: "appBar.dark" }}>
                        <ContentContainer>
                            <Box pt="4px" pb="12px">
                                <Heading size="md" textAlign="left" color="primary.500">
                                    {`Totaux ${de_(getShowedMonthDt().toFormat("MMMM"))}`}
                                </Heading>
                            </Box>
                            {sortBy(matchInfosListQuery.data.currentMonthMatches, (m) => m.Program.Learner.firstName).map((mi, idx) => {
                                const matchRealLessons = currentRealLessonsQuery.helpers.findLessonsByMatchId(mi.id)
                                const matchRealLessonsSum = sumBy(matchRealLessons, (lsn) => lsn.durationMin ?? 0)

                                return (
                                    <React.Fragment key={mi.id}>
                                        <HStack alignItems="center" justifyContent="space-between">
                                            <VStack>
                                                <Text>
                                                    {mi.Program.Learner.firstName} {mi.Program.Learner.lastName}
                                                </Text>
                                                <Text>{mi.label}</Text>
                                            </VStack>
                                            <Text fontSize="lg">{formatDurationMin(matchRealLessonsSum)}</Text>
                                        </HStack>
                                        {idx !== matchInfosListQuery.data.currentMonthMatches.length - 1 && <Divider my="8px" />}
                                    </React.Fragment>
                                )
                            })}
                        </ContentContainer>
                    </Box>
                    <ContentContainerColumn space="15px" paddingY="15px">
                        {lessons.map((lesson) => (
                            <LessonCard key={lesson.id} matchId={lesson.Match} lessonId={lesson.id} />
                        ))}
                    </ContentContainerColumn>
                </ScrollView>
            )}
            <Animated.View
                style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: barBg,

                    shadowColor: "#000000",
                    shadowOpacity: 0.32,
                    shadowOffset: { width: 0, height: 0 },
                    shadowRadius: 2.22,
                    height: onScrollAnim.interpolate({ inputRange: [0, BAR_HEIGHT - 1, BAR_HEIGHT], outputRange: [BAR_HEIGHT, BAR_HEIGHT, 0], extrapolate: "clamp" }),
                    opacity: onScrollAnim.interpolate({ inputRange: [0, SCROLL_ANIMATION_HEIGHT], outputRange: [1, 0] }),
                }}
            >
                <Center height="full">
                    <Button
                        onPress={() => navigation.getParent<NavigationProp<MainStackParamsList>>()?.navigate("AddLessonSelectMatch")}
                        colorScheme="pedagome"
                        variant="solid"
                        leftIcon={<Icon as={FontAwesome5} name="plus" size="xs" />}
                    >
                        Déclarer un cours
                    </Button>
                </Center>
            </Animated.View>
        </VStack>
    )
}

function NoLessonsMessage() {
    return (
        <ContentContainerColumn paddingY="15px" alignItems="center" flex={1}>
            <Center flexGrow={1}>
                <Box p="20px" borderRadius="10px" _light={{ bgColor: "light.200" }} _dark={{ bgColor: "dark.200" }}>
                    <Text textAlign="center" _light={{ color: "light.700" }} _dark={{ color: "dark.700" }}>
                        Encore aucun cours n'a été renseigné ou prévu pour {getShowedMonthDt().toFormat("MMMM yyyy")}.
                    </Text>
                </Box>
            </Center>
        </ContentContainerColumn>
    )
}

// {userInfo.hasCurrentlyAllSubmitted ?
//   <Center flexGrow={1}>
//     <ContentContainer>
//       <Box p="20px" borderRadius="10px" _light={{ bgColor: 'light.200' }} _dark={{ bgColor: 'dark.200' }}>
//         <Text textAlign="center" _light={{ color: 'light.700' }} _dark={{ color: 'dark.700' }}>{userInfo.lastPlanningSubmissionDangerous?.month === getCurrentMonthDt().toSQLDate() ? "Toutes tes heures sont déjà déclarées." : `Aucun cours pour lequel déclarer des heures en ${userInfo.currentDisplayedMonthDt.toFormat('MMMM')}.\n\nIls apparaitront ici une fois ta proposition signée et après acceptation par l'élève.`}</Text>
//       </Box>
//     </ContentContainer>
//   </Center>
//   :
//   <VStack space={3} flexGrow="1">

//     <ContentContainer space="15px" paddingY="15px">
//       {showedMatchInfos.map((matchInfo, showedMatchIndex) =>
//         <PlanningMatchCard key={matchInfo.id} matchId={matchInfo.id} matchIndex={showedMatchIndex} navigation={navigation} />
//       )}
//     </ContentContainer>

//   </VStack>}

// {!userInfo.hasCurrentlyAllSubmitted &&
//   <Center /* mb="65px" */ mb="125px" _web={{ mb: "65px" }}>
//     <Button isDisabled={hasMissingQuestions} onPress={() => navigation.getParent<StackNavigationProp<MainStackParamsList, "MainTabs">>().navigate('ConfirmSubmission')} mt="2" colorScheme="pedagome" _text={{ color: 'lightText' }} leftIcon={<Icon as={FontAwesome5} name="paper-plane" color="white" size="xs" />}>
//       Envoyer mes réponses
//     </Button>
//   </Center>}
