import { Card } from "@core/atoms/Card"
import { useAuthContext } from "@core/context/auth"
import { useCatchToBoundary } from "@core/hooks"
import { ContentContainer } from "@core/misc-components/ContentContainer"
import { LoadingScreen } from "@core/organism/LoadingScreen"
import { match_infos_list_query } from "@core/queries"
import { monthly_match_surveys_query } from "@core/queries/monthly_match_surveys"
import { openapi } from "@core/services/openapi"
import type { MonthlyMatchSurveyConfigBlock } from "@core/services/rasmik-client"
import { de_ } from "@core/utils/textUtils"
import { InputControl, Radio, RadioGroupControl, TextAreaControl } from "@native-base/formik-ui"
import type { StackScreenProps } from "@react-navigation/stack"
import { useQueryClient } from "@tanstack/react-query"
import { Formik } from "formik"
import { produce } from "immer"
import { DateTime } from "luxon"
import { Button, Center, ChevronLeftIcon, ChevronRightIcon, Heading, HStack, IconButton, Progress, Spacer, Text, VStack } from "native-base"
import { useCallback, useState } from "react"

import type { MainStackParamsList } from "../MainStack"

type FormValues = {
    if?: "true" | "false" | null
    main: string | null
    comment?: string | null
}

/**
 * Si la question n'est pas valide et qu'on appelle submitForm, formik saute la soumission.
 * Du coup, pour un retour arrière, la réponse ne sera pas sauvegardé (c'est cool)
 * Et pour un next, les boutons seront disabled donc pas de risque.
 */

export function EditSurveyScreen(props: StackScreenProps<MainStackParamsList, "EditSurvey">) {
    const {
        navigation,
        route: {
            params: { surveyId },
        },
    } = props

    const auth = useAuthContext()

    const qc = useQueryClient()

    const { matchInfosListQuery } = match_infos_list_query.use({ teacherId: auth.userId })
    const { monthlyMatchSurveysQuery } = monthly_match_surveys_query.use({ status: "DRAFT", teacherId: auth.userId })

    const mms = monthlyMatchSurveysQuery.helpers.findBy({ id: surveyId })
    const match = matchInfosListQuery.helpers.findById(mms?.Match)

    const noLessons = mms && !mms.lessonsDurationSum
    const isFinishing = mms?.questions.some((question) => question.key === "ARRET_COURS" && question.if?.answer === "true")
    const showableQuestions = mms?.questions.filter((q) => !(/* q.skipped || */ ((noLessons && q.skipWhenNoLessons) || (isFinishing && q.skipWhenMatchFinishing)))) ?? []
    const questionsCount = showableQuestions.length
    const stepper = useSteps(showableQuestions.length, getFirstNotOkStep(showableQuestions))

    if (matchInfosListQuery.isLoading || monthlyMatchSurveysQuery.isLoading) return <LoadingScreen />

    // const currentQuestion = mms?.questions.at(stepper.currentStepIndex)
    const currentQuestion = showableQuestions.at(stepper.currentStepIndex)

    if (!currentQuestion || !mms || !match) return null
    return (
        <>
            <VStack height="100%" safeAreaBottom>
                <Formik<FormValues>
                    key={currentQuestion.rank}
                    enableReinitialize
                    initialValues={{
                        if: currentQuestion.if?.answer || null,
                        main: currentQuestion.answer || "",
                        comment: currentQuestion.freeComment?.answer || "",
                    }}
                    validate={(values) => {
                        const errors: any = {}
                        if (currentQuestion.if && !values.if) {
                            errors.if = "Obligatoire"
                        }
                        if (values.if !== "false" && currentQuestion.required && !values.main) {
                            errors.main = "Obligatoire"
                        }

                        return errors
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        const isDirty =
                            (values.if || null) !== (currentQuestion.if?.answer || null) ||
                            (values.main || null) !== (currentQuestion.answer || null) ||
                            (values.comment || null) !== (currentQuestion.freeComment?.answer || null)

                        if (isDirty) {
                            const nextQuestion = produce(currentQuestion, (draft) => {
                                draft.answer = values.main ?? undefined
                                if (draft.if) draft.if.answer = values.if ?? undefined
                                if (draft.freeComment) draft.freeComment.answer = values.comment ?? undefined
                            })

                            await auth.ensureValidToken()
                            console.debug("matchListQuery")

                            const newDbMMS = await openapi.teachersApp.saveOneAnswer(surveyId, nextQuestion)

                            monthlyMatchSurveysQuery.helpers.patch(surveyId, newDbMMS)

                            matchInfosListQuery.helpers.invalidate()
                        }

                        setSubmitting(false)
                    }}
                >
                    {function XXX({ values, submitForm, isValid, isSubmitting, validateForm }) {
                        const submitBack = useCatchToBoundary(async () => {
                            await submitForm()

                            if (stepper.isFirstStep) {
                                qc.invalidateQueries({ queryKey: monthly_match_surveys_query.getKey({ status: "DRAFT", teacherId: auth.userId }) })
                                qc.invalidateQueries({ queryKey: match_infos_list_query.getKey({ teacherId: auth.userId }) })
                                navigation.goBack()
                            } else {
                                stepper.previousStep()
                            }
                        })

                        const submitNext = useCatchToBoundary(async () => {
                            const errors = await validateForm()

                            if (Object.keys(errors).length > 0) {
                                return
                            }

                            await submitForm()

                            if (stepper.isLastStep) {
                                qc.invalidateQueries({ queryKey: monthly_match_surveys_query.getKey({ status: "DRAFT", teacherId: auth.userId }) })
                                qc.invalidateQueries({ queryKey: match_infos_list_query.getKey({ teacherId: auth.userId }) })
                                navigation.goBack()
                            } else {
                                stepper.nextStep()
                            }
                        })

                        return (
                            <>
                                <VStack /* _light={{ backgroundColor: "appBar.light" }} _dark={{ backgroundColor: "appBar.dark" }} */>
                                    <Progress colorScheme="success" _text={{ color: "lightText" }} value={stepper.currentStep} max={questionsCount} />
                                    <Center safeAreaTop>
                                        <Heading _web={{ marginTop: "25px" }} fontSize="xl">
                                            Questionnaire {de_(DateTime.fromISO(mms.month).toFormat("MMMM"))}
                                        </Heading>
                                        <Heading fontSize="lg">
                                            {match.label} avec {match?.Program?.Learner?.firstName || ""} {match?.Program?.Learner?.lastName || ""}
                                        </Heading>

                                        <HStack alignItems="center" marginTop="15px">
                                            <IconButton
                                                isDisabled={stepper.isFirstStep}
                                                disabled={stepper.isFirstStep}
                                                onPress={submitBack}
                                                variant="ghost"
                                                icon={<ChevronLeftIcon _light={{ color: "darkText" }} _dark={{ color: "lightText" }} /* size="xs" */ />}
                                            />
                                            <Text>
                                                {stepper.currentStep} / {questionsCount}
                                            </Text>
                                            <IconButton
                                                isDisabled={!isValid || stepper.isLastStep || stepper.isMaxVisitedStep}
                                                disabled={!isValid || stepper.isLastStep || stepper.isMaxVisitedStep}
                                                onPress={submitNext}
                                                variant="ghost"
                                                icon={<ChevronRightIcon _light={{ color: "darkText" }} _dark={{ color: "lightText" }} /* size="xs" */ />}
                                            />
                                        </HStack>
                                    </Center>
                                </VStack>

                                <VStack flex={1} justifyContent="center">
                                    <Center>
                                        <ContentContainer space="15px" paddingY="15px">
                                            <Card>
                                                <VStack space="4">
                                                    <Heading marginBottom="10px">{currentQuestion.header}</Heading>

                                                    {currentQuestion.if && (
                                                        <RadioGroupControl key={`q${currentQuestion.rank}.if`} name="if" label={currentQuestion.if.question}>
                                                            <Radio key="if-1" value={currentQuestion.if.first === "true" ? "true" : "false"} my="5px">
                                                                {currentQuestion.if.first === "true" ? currentQuestion.if.true : currentQuestion.if.false}
                                                            </Radio>
                                                            <Radio key="if-2" value={currentQuestion.if.first === "true" ? "false" : "true"} my="5px">
                                                                {currentQuestion.if.first === "true" ? currentQuestion.if.false : currentQuestion.if.true}
                                                            </Radio>
                                                        </RadioGroupControl>
                                                    )}

                                                    {(!currentQuestion.if || values.if === "true") && (
                                                        <>
                                                            {currentQuestion.type === "text-short" && <InputControl key={`q${currentQuestion.rank}.main`} name="main" label={currentQuestion.question} size="md" />}

                                                            {currentQuestion.type === "text-long" && <TextAreaControl key={`q${currentQuestion.rank}.main`} name="main" label={currentQuestion.question} />}

                                                            {currentQuestion.type === "radio" && (
                                                                <RadioGroupControl key={`q${currentQuestion.rank}.main`} name="main" label={currentQuestion.question}>
                                                                    {currentQuestion.options?.map((option) => (
                                                                        <Radio key={option} value={option} marginY="5px">
                                                                            {option}
                                                                        </Radio>
                                                                    ))}
                                                                </RadioGroupControl>
                                                            )}

                                                            {currentQuestion.helper && <Text fontSize="xs">{currentQuestion.helper}</Text>}
                                                        </>
                                                    )}

                                                    {!(currentQuestion.freeComment && <InputControl key={`q${currentQuestion.rank}.comment`} name="comment" label={currentQuestion.freeComment.question} />)}
                                                </VStack>

                                                <HStack alignItems="center" justifyContent="space-between" space="10px" mt="20px">
                                                    <Button isLoading={isSubmitting} colorScheme="coolGray" onPress={submitBack}>
                                                        {stepper.isFirstStep ? "Retour" : "Précédent"}
                                                    </Button>
                                                    <Spacer />
                                                    <Button isLoading={isSubmitting} isDisabled={!isValid} disabled={!isValid} onPress={submitNext}>
                                                        {stepper.isLastStep ? "Terminer" : "Suivant"}
                                                    </Button>
                                                </HStack>
                                            </Card>
                                        </ContentContainer>
                                    </Center>
                                </VStack>
                            </>
                        )
                    }}
                </Formik>
            </VStack>
        </>
    )
}

function isQuestionOk(question: MonthlyMatchSurveyConfigBlock) {
    if (question.required) {
        if (question.if) {
            return question.if.answer === "false" || (question.if.answer === "true" && Boolean(question.answer))
        } else {
            return Boolean(question.answer)
        }
    } else {
        return Boolean(question.visited)
    }
}

function getFirstNotOkStep(showableQuestions: MonthlyMatchSurveyConfigBlock[]) {
    let nb = 0

    for (const q of showableQuestions) {
        nb++
        const isOk = isQuestionOk(q)
        if (!isOk) {
            break
        }
    }

    return nb
}

function useSteps(stepCount: number, maxVisitedStep: number = 1) {
    const [currentStep, setCurrentStep] = useState(1)
    // const [maxVisitedStep, setMaxVisitedStep] = useState(mxVisitedStep)

    // useEffect(() => {
    //     setCurrentStep(1)
    //     // setMaxVisitedStep(mxVisitedStep)
    // }, [stepCount/* , mxVisitedStep */])

    return {
        currentStep,
        currentStepIndex: currentStep - 1,
        setCurrentStep,
        nextStep: useCallback(() => {
            if (currentStep < stepCount) {
                setCurrentStep(currentStep + 1)
                //currentStep + 1 > maxVisitedStep && setMaxVisitedStep(currentStep + 1)
            }
        }, [stepCount, currentStep]),
        previousStep: useCallback(() => currentStep > 1 && setCurrentStep((step) => step - 1), [currentStep]),
        isFirstStep: currentStep === 1,
        isLastStep: currentStep === stepCount,
        // setMaxVisitedStep,
        maxVisitedStep,
        isMaxVisitedStep: currentStep === maxVisitedStep,
    }
}
